import React from 'react'
import { useSelector } from 'react-redux'

import { getChapter, getCaseComplete } from '../reducers/caseSlice'

const DashboardBanner = () => {
    const activeChapter = useSelector(getChapter)
    const caseComplete = useSelector(getCaseComplete)

    return (
        <strong>
            { caseComplete === true ? 'CASE COMPLETE' : `DAY ${activeChapter}` }
        </strong>
    )
}

export default DashboardBanner