import {  collection, addDoc, query, where, getDocs, doc, updateDoc, DocumentData } from 'firebase/firestore';

import { db } from '../firebase'
import userType, { fsCaseType } from '../components/types/user'
import settings from '../settings.json'

interface updateUserType {
    uid: string,
    userUpdates: {}
}

/*
 * CREATE functions
*/

const createUser = (uid: string, caseId?: string) => {
    // note: empty arrays dont actually get created. Just here for reference
    const userCaseId = caseId || settings.default.case
    const newUser: userType = {
        uid,
        cases: [],
        activeCase: {
            id: userCaseId,
            chapter: 1,
            puzzles: [],
            poi: [],
            scenes: [],
            items: [],
            path: []
        }
    }
    return addDoc(collection(db, 'users'), newUser)
        .then((docRef) => {
            console.log('User created with ID: ', docRef.id, ', for case: ', userCaseId);
            return true
        })
        .catch((error) => {
            console.error('Error adding document: ', error);
            return false
        })
}

/*
 * RESET functions
*/

const resetUserCase = async (caseId: string) => {
    return await updateUserActiveCase({
        id: caseId,
        chapter: 1,
        puzzles: [],
        poi: [],
        scenes: [],
        items: [],
        path: []
    })
}

/*
 * GET functions
*/

const getSessionUID = () => {
    const uid = sessionStorage.getItem(`uid`)
    return uid
}

const getUserDocID = async (uid?: string) => {
  const sessionUID = sessionStorage.getItem(`uid`)
  const targetUID = uid ? uid : sessionUID

  try {
    // Get the 'users' collection and apply a query with 'where' condition
    const usersCollection = collection(db, 'users');
    const usersQuery = query(usersCollection, where('uid', '==', targetUID));  // Create a query

    // METHOD #1/3 using new firestore funcs

    // Execute the query and return the result
    const querySnapshot = await getDocs(usersQuery); 
    
    // return the id of the document
    return await querySnapshot?.docs[0].id
  } catch (error) {
    console.log('Error getting user: ', uid);
  }
}

const getUser = async (uid?: string) => {
    const sessionUID = sessionStorage.getItem(`uid`)
    const targetUID = uid ? uid : sessionUID
    if (!targetUID) {
        return null
    }

    try {
      // Get the 'users' collection and apply a query with 'where' condition
      const usersCollection = collection(db, 'users');
      const usersQuery = query(usersCollection, where('uid', '==', targetUID));  // Create a query

      // METHOD #2/3 using new firestore funcs

      // Execute the query using getDocs()
      return await getDocs(usersQuery)
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          // Extract the first document's data
          const user = querySnapshot.docs[0].data();

          // If debug setting is true, log the user data
          if (settings.debug) {
            console.log('GET user: ', user);
          }

          // Return the user data
          return user;
        }
      })
      .catch((error) => {
        console.error('Error fetching user: ', error);
        return null;  // Handle the error, return null or a custom error value
      });
    } catch (error) {

    }
}

const getUserActiveCase = async (uid?: string) => {
    // sessionStorage.setItem('activeCase', JSON.stringify(user.activeCase))
    const activeCaseSessionJSON = sessionStorage.getItem('activeCase')
    const activeCaseSession = activeCaseSessionJSON ? JSON.parse(activeCaseSessionJSON) : null
    if (settings.debug) {
        console.log('getUserActiveCase() active case found in session storage:', activeCaseSession);
    }
    
    if (activeCaseSession?.id) {
        // if active case exists in session storage, send that.
        return activeCaseSession
    } else {
        if (settings.debug) {
            console.log('getUserActiveCase (user) not found in session storage. get user from firebase');
        }
        // if not, query firebase and update session storage
        const user = await getUser(uid)
        // update the active case in local storage
        if (user) {
            updateActiveCaseSessionStorage(user.activeCase)
            return user.activeCase
        } else {
            return null
        }
    }
}

/*
 * UPDATE functions
*/

const updateUser = async (userUpdates: {}) => {
    console.log('TODO: DOES THIS EVER GET CALLED???');
    // get the user doc id
    // todo: type 'any' should not be used, but cant get DocumentData[] to load right after upgrading to new firestore
    const docID: any | DocumentData[] | undefined = await getUserDocID()

    // get the user obj
    const user = await getUser()
    const updatedUser = {
        ...user,
        ...userUpdates
    }
    console.log('POST update user');
    
    if (!user || !docID) {
        return false
    }

    // update the active case in local storage
    updateActiveCaseSessionStorage(user.activeCase)

    // METHOD #3/3 using new firestore funcs

    // Reference the 'users' collection and the specific document by ID
    const userDocRef = doc(collection(db, 'users'), docID);
    // const userDocRef = doc(db, 'users', docID);
    
    // Update the document on Firestore
    return await updateDoc(userDocRef, updatedUser)
    .then(() => {
      if (settings.debug) {
        console.log('User updated on Firestore:', updatedUser);
      }
    })
    .catch((error) => {
      console.error(`Error updating document: ${docID}`, error);
    });
}

const updateUserActiveCase = async (updates: fsCaseType) => {
    const { id, items, puzzles, poi, scenes, path, chapter, dashScenesQueue } = updates

    const activeCase = await getUserActiveCase()
    const activeCaseUpdates = {
        ...activeCase,
        ...updates 
    }

    updateActiveCaseSessionStorage(activeCaseUpdates)

    if (settings.debug) {
        console.log('updateUserActiveCase() update active case in session storage and firebase:', activeCaseUpdates);
    }
    
    // get the user doc id
    // todo: type 'any' should not be used, but cant get DocumentData[] to load right after upgrading to new firestore
    const docID: any = await getUserDocID()
    if (!docID) {
        throw('Error loading user')
    }

    // Reference the 'users' collection and the specific document by ID
    console.log('docId', docID);
    console.log('activeCaseUpdates',activeCaseUpdates);
    
    
    const userDocRef = doc(collection(db, 'users'), docID);
    // const userDocRef = doc(db, 'users', docID);

    // Update the document on Firestore
    return await updateDoc(userDocRef, { activeCase: activeCaseUpdates })
    .then(() => {
      if (settings.debug) {
        console.log('User updated:', activeCaseUpdates);
      }
    })
    .catch((error) => {
      console.error(`Error updating document: ${docID}`, error);
    });
}

const updateActiveCaseSessionStorage = (activeCase: {}) => {
    // update the active case in local storage
    sessionStorage.setItem('activeCase', JSON.stringify(activeCase))
}

export {
    getSessionUID,
    createUser,
    getUser,
    getUserActiveCase,
    updateUser,
    updateUserActiveCase,
    resetUserCase
}