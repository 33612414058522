import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { getChapter, setChapter, setCaseComplete } from '../reducers/caseSlice'
import  { getChapterStatus, addItemToPath } from '../../utils'
import settings from '../../settings.json'
import { current } from 'immer' // ????

if (settings.debug) {
    console.log('C HA  PT T ER');
}

const Chapter = () => {
    const dispatch = useDispatch()
    const currentChapter = useSelector(getChapter)

    const updateChapterState = async () => {
        const status = await getChapterStatus(currentChapter)
        if (status.caseComplete) {
            // '**** Chapter: Case Complete ****')
            dispatch(setCaseComplete(true))
        } else if (status.chapterComplete) {
            // **** Chapter: Chapter Complete *****');
            dispatch(setChapter(status.activeChapter))
            await addItemToPath(`chapter-${status.activeChapter}`)
        }
    }

    useEffect( () => {
        updateChapterState()
    }, [])
    
    return (<></>)
}

export default Chapter