import React, { useRef, useState } from "react"
import { useAuth } from "./contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"

export default function UpdateProfile() {
    const displayNameRef = useRef()
    const emailRef = useRef()
    const passwordRef = useRef()
    const passwordConfirmRef = useRef()
    const { currentUser, updatePassword, updateEmail, updateProfile } = useAuth()
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    function handleSubmit(e) {
        e.preventDefault()
        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError("Passwords do not match")
        }

        const promises = []
        setLoading(true)
        setError("")

        if (emailRef.current.value !== currentUser.email) {
            promises.push(updateEmail(emailRef.current.value))
        }
        if (passwordRef.current.value) {
            promises.push(updatePassword(passwordRef.current.value))
        }

        Promise.all(promises)
            .then(() => {
                history.push("/")
            })
            .catch(() => {
                setError("Failed to update account")
            })
            .finally(() => {
                setLoading(false)
            })
    }

    async function updateUserProfile() {
        try {
            setError("")
            console.log('TODO: add other fields. Only display name and email get updated');
            const formValues = {
                displayName: displayNameRef.current.value,
                email: emailRef.current.value,
                // TODO: add other fields
                // photoUrl:
            }
            await updateProfile(formValues)
        } catch {
            setError("Failed to update profile")
        }
    }

    return (
        <>
            <div>
                <h2>Update Profile</h2>
                {error && <div>{error}</div>}
                <form onSubmit={handleSubmit}>
                    <label id="displayName">Display Name</label>
                    <input
                        type="text"
                        ref={displayNameRef}
                        defaultValue={currentUser.displayName}
                    /><br/>
                    <label id="email">Email</label>
                    <input
                        type="email"
                        ref={emailRef}
                        required
                        defaultValue={currentUser.email}
                    /><br/>
                    <label id="password">Password</label>
                    <input
                        type="password"
                        ref={passwordRef}
                        placeholder="Leave blank to keep the same"
                    /><br/>
                    <label id="password-confirm">Password Confirmation</label>
                    <input
                        type="password"
                        ref={passwordConfirmRef}
                        placeholder="Leave blank to keep the same"
                    /><br/>
                    <button disabled={loading} type="submit" disabled>Update</button>
                </form>
            </div>
            <button onClick={updateUserProfile}>Update Profile</button>
            <div>
                <Link to="/">Cancel</Link>
            </div>
        </>
    )
}
