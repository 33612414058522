import React from 'react'
import { useSelector } from 'react-redux'

import { getUser } from '../reducers/userSlice'

const User = () => {
    const user = useSelector(getUser)
    const displayName = user ? user.displayName : 'No Display Name'

    return <div className="component">displayName: {displayName}</div>
}

export default User