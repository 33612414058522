// compile all globals
import missingPersons from './missing-persons'
import telephone from './telephone'
import items from './items'

export default {
    globalPOI: {
        missingPersons,
        telephone
    },
    items: items
}