import { createSlice } from '@reduxjs/toolkit'

import { poiType } from '../types/poi'

const initialState:poiType | null = null

export const poiSlice = createSlice({
    name: 'poi',
    initialState,
    reducers: {
        resetPOI: () => {
            return initialState
        },
        setPOI: (state, action) => {
            return action.payload
        }
    }
})

export const { resetPOI, setPOI } = poiSlice.actions

// Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const getPOIID = (state: { activePOI: { id: any; }; }) => state.activePOI ? state.activePOI.id : null
export const getPOI = (state: { activePOI: any; }) => state.activePOI


export default poiSlice.reducer
