import React from 'react'
import TextField from '@mui/material/TextField';
import clsx from 'clsx'
import { makeStyles } from 'tss-react/mui';

interface inputNoirType {
    label?: string,
    variant?: "outlined" | "standard" | "filled" | undefined,
    loading?: boolean,
    // All other props
    [x:string]: any,
}

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
        },
    }
})

const sxClasses = {
    muiRoot: {
        width: '95%',
        border: 'solid 2px #fff',
        margin: 'auto'
    },
}

const SelectNoir = ({ label = '', variant = 'outlined', ...props }: inputNoirType) => {
    const { classes } = useStyles()

    return (
        <div className={classes.root}>
            <TextField sx={sxClasses.muiRoot} label={label} variant={variant} {...props} />
        </div>
    )
}

export default SelectNoir