// credit: https://bareynol.github.io/mui-theme-creator/

import { createTheme } from '@mui/material/styles';

export const noirTheme = {
  palette: {
    alphaLight: {
      color: 'rgb(255 255 255 / .7)',
    },
    alphaDark: {
      color: 'rgb(0 0 0 / .7)',
    },
  },
};

const theme = createTheme({
  palette: {
    background: {
      default: '#000', //transparent',//'#16161d',
    },
    primary: {
      light: '#5B5F6E',
      main: '#33384a',
      dark: '#232733',
      contrastText: '#fff',
    },
    secondary: {
      light: '#CA4446',
      main: '#BD1618',
      dark: '#840F10',
      contrastText: '#fff',
    },
    text: {
      primary: '#fff',
    },
    action: {
      disabledBackground: '#232733',
      disabled: 'white',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1000, // 1280 mui default
      xl: 1280, // 1920 mui default
    },
  },
  typography: {
    fontFamily: `'Helvetica', 'sans-serif'`,
    h1: {
      fontSize: '4rem',
    },
    h2: {
      fontSize: '2.75rem',
    },
    h3: {
      fontSize: '2rem',
    },
    h4: {
      fontSize: '1.125rem',
    },
    h5: {
      fontSize: '1rem',
    },
    h6: {
      fontSize: '0.5rem',
    },
    subtitle1: {
      fontFamily: `'Arial', 'Sans-serif'`,
    },
    subtitle2: {
      fontFamily: `'Arial', 'Sans-serif'`,
      fontSize: '0.8rem',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 0,
      },
    },
  },
});

export default theme;
