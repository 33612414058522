import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' 
// import storageSession from 'reduxjs-toolkit-persist/lib/storage/session'
import ReduxThunk from 'redux-thunk'

// import counterReducer from '../components/counter/counterSlice'
import activePOIReducer from '../components/reducers/poiSlice'
import activeSceneReducer from '../components/reducers/sceneSlice'
import myLocationReducer from '../components/reducers/myLocationSlice'
import caseReducer from '../components/reducers/caseSlice'
import userReducer from '../components/reducers/userSlice'
import debugReducer from '../components/reducers/debugSlice'

const reducers = combineReducers({
    // counter: counterReducer,
    activePOI: activePOIReducer,
    activeScene: activeSceneReducer,
    myLocation: myLocationReducer,
    case: caseReducer,
    user: userReducer,
    debug: debugReducer,
});

const persistConfig = {
    key: 'root',
    storage: storage, // or storageSession
    whitelist: ['activePOI', 'activeScene', 'myLocation', 'case'],
    blacklist: ['user', 'debug']
}

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [ReduxThunk],
})