// compile all globals
import missingPersons from './missing-persons'
import telephone from './telephone'
import items from './items'
import subway from './subway.json'

export default {
    globalPOI: {
        missingPersons,
        telephone,
        subway
    },
    items: items
}