import React, { useRef, useState } from "react"
import { useAuth } from "./contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import queryString from 'query-string'

import { getUserActiveCase, createUser } from '../../utils'

// ------- Firebase Auth UI ---------
// var firebase = require('firebase')
// const firebaseui = require('firebaseui')

// export default function Login() {
const Login = ( props ) => {
    const emailRef = useRef()
    const passwordRef = useRef()
    const { login, createAnonymousUser } = useAuth()
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    // get case from query params
    const params = queryString.parse(props.location.search)
    const paramCaseId = params.case || undefined

    async function handleSubmit(e) {
        e.preventDefault()

        try {
            setError('')
            setLoading(true)
            await login(emailRef.current.value, passwordRef.current.value)
            await getUserActiveCase()
            setLoading(false)
            history.push('/')
        } catch (e) {
            setLoading(false)
            console.log('e', e);
            setError(`Failed to log in: ${e.message}`)
        }
    }

    async function handleCreateAnonymous(e) {
        try {
            setError("")
            setLoading(true)
            const response = await createAnonymousUser()
            setLoading(false)
            // add user to firestore and redirect to home
            if(!createUser(response.user.uid, paramCaseId)) {
                throw 'User not created in firestore'
            }
            history.push("/")
        } catch {
            setLoading(false)
            setError("Failed to create anonymous user")
        }
    }

    return (
        <>
            <h2>Log In</h2>
            <h3>Case: {paramCaseId}</h3>
            {error && <div>{error}</div>}
            <form onSubmit={handleSubmit}>
                <label>Email</label>
                <input type="email" ref={emailRef} required /><br/>
                <label>Password</label>
                <input type="password" ref={passwordRef} required /><br/>
                <button disabled={loading} type="submit">
                    Log In
                </button>
            </form>

            <Link to="/forgot-password">Forgot Password?</Link><br/>

            <button onClick={handleCreateAnonymous}>Anonymous</button><br/>

            <Link to="/signup">Sign Up</Link>
        </>
    )
}

export default Login