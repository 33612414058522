// import { resolve } from 'node:url';
import { getUserActiveCase, updateUserActiveCase } from '../utils'

export const addItemToPath = async (id: string | string[]) => {
    // only add item to path, if it doesnt already exist
    const activeCase = await getUserActiveCase()
    const path = activeCase && activeCase.path ? activeCase.path : []
    let updateUser = false

    const verifyIdNotInPath = (id: string): void => {
        // verify id isnt already in path, if so, add to path
        const itemExists = path.find((item: string) => item === id)
        if (!itemExists) {
            updateUser = true
            path.push(id)
        }
    }

    if (typeof id === 'string') {
        // string - add single id to path
        console.log('check the single path is working...');
        
        verifyIdNotInPath(id)
    } else {
        // array - add multiple id's to path
        id.forEach((item: string) => verifyIdNotInPath(item))
    }
    if (updateUser) {
        const distinctPath = [...new Set([...path])]; // distinct filter dashScenes array
        await updateUserActiveCase({path})
    }
}

export const getPath = async () => {
    const activeCase = await getUserActiveCase()
    return activeCase.path ? activeCase.path : []
}

export const pathContainsItem = async (id: string) => {
    const activeCase = await getUserActiveCase()
    const path = activeCase && activeCase.path ? activeCase.path : []
    return !!path.some((item: string) => item === id)   
}

export const isItemInPath = async (id: string) => {
    const activeCase = await getUserActiveCase()
    const path = activeCase && activeCase.path ? activeCase.path : []
    return path.find((item: string) => item === id)
}