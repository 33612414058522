import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { getChapter } from '../../reducers/caseSlice'
import { setUserPOIStatus, addItemToPath, getPath } from '../../../utils'

import SlugoBizAImg from '../../../data/cases/001-the-mollusck/media/day-3/script/slugo-biz-a.jpg'
import SlugoBizBImg from '../../../data/cases/001-the-mollusck/media/day-3/script/slugo-biz-b.jpg'

const styles = {
    marginTop: 50,
    width: '100%'
}

const MollusckSlugoBiz = () => {
    const currentChapter = useSelector(getChapter || 1)
    const [activateSlugoBiz, setActivateSlugoBiz] = useState(false)

    useEffect(() => {
        const checkIfActive = async () => {
            const pathsVisited = await getPath()
            if (currentChapter >= 3 && !pathsVisited.includes('slugo-biz')) {
                setActivateSlugoBiz(true)
                setCookie({ cName: 'slugo-biz', cValue: 'visited', expMins: 1 });
                const updateUser = async () => {
                    await setUserPOIStatus({ id: 'slugo-biz' })
                    await addItemToPath('slugo-biz')
                }
                updateUser()
            }
        }
        checkIfActive()
    }, [])

    function setCookie({ cName, cValue, expMins }: { cName: string, cValue: string, expMins: number }) {
        // set exp to minutes from now
        const date = new Date()
        date.setTime(date.getTime() + expMins * 60000);
        // create cookie
        const expires = "expires=" + date.toUTCString();
        document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
    }

    return (
        <>
            { activateSlugoBiz ?
                <>
                    <img src={SlugoBizAImg} style={styles} />
                    <img src={SlugoBizBImg} style={styles} />
                </>
                : null
            }
        </>
    )
}

export default MollusckSlugoBiz