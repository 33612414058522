import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'
import clsx from 'clsx'

import { getPOI } from '../reducers/poiSlice'
import { setScene } from '../reducers/sceneSlice'
import backIcon from '../../images/back-icon.png'

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      // marginLeft: '1vw',
      paddingTop: 10,
      display: 'inline-block',
      textDecoration: 'none',
      width: '100%',
      '& span': {
        position: 'relative',
        top: '-10px',
        fontSize: '19px',
        left: '0px',
        fontWeight: 600,
        textTransform: 'uppercase',
        fontFamily: 'serif',
      },
    },
    overlay: {
      background: theme.palette.primary[100],
      border: 'solid white 2px',
      position: 'absolute',
      zIndex: 10,
      top: -30,
      paddingLeft: 10,
      paddingRight: 10,
      a: {
        color: theme.palette.primary[800],
      },
    },
    backIcon: {
      height: 34,
      width: 44,
    },
  }
})

const Breadcrumbs = ({ overlay }: { overlay?: boolean }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { classes } = useStyles()
  const poi = useSelector(getPOI)

  const goBack = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault()
    const historyState: any = history?.location?.state || {}

    const { parentScene } = historyState || {}

    if (historyState?.parentScene) {
      dispatch(setScene(parentScene))
    }
    history.goBack()
  }

  if (!poi) {
    return <></>
  }
  return (
    <div
      className={clsx('component', classes.root, overlay && classes.overlay)}
    >
      <a onClick={(e) => goBack(e)}>
        <img className={classes.backIcon} src={backIcon} />
        <span>Back</span>
      </a>
    </div>
  )
}

export default Breadcrumbs
