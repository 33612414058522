import React from 'react'
import { Button, CircularProgress, Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx'

interface progressNoirType {
    show?: boolean,
    color?: 'inherit' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | undefined,
    // All other props
    [x:string]: any,
}

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            display: 'flex'
        },
    }
})

const ProgressNoir = ({ show = true, color, ...props }: progressNoirType) => {
    const { classes } = useStyles()

    if (!show) {
        return (<></>)
    }

    return (
        <Box className={classes.root}>
            <CircularProgress color={color} {...props} />
        </Box>
    )
}

export default ProgressNoir