import React, { useEffect, useState } from 'react'

import { getAllUserItems, getCaseData } from '../../utils'

const Items = () => {
  const [ itemsHidden, setItemsHidden ] = useState<boolean>(true)
    const [ items, setItems ] = useState<any[]>()

    const checkIfItemsHidden = async () => {
      const caseData = await getCaseData()
      setItemsHidden(caseData?.hideItems || false)
    }

    const fetchItems = async () => {
        const items = await getAllUserItems({ asObject: true }) || []
        setItems(items)
    }

    useEffect(() => {
      checkIfItemsHidden()
      fetchItems()
    }, [])
    
    const listOfItemsHTML = items?.length ? items.map(item => <div key={item.id}>{item.name}</div>) : null

    return (
        itemsHidden ? <></> :
        <div className="component">
            <h3>Items</h3>
            { items?.length ? listOfItemsHTML : `You've got nothing.`}
        </div>
    )
}

export default Items