import React, { useRef, useState } from "react"
import { useAuth } from "./contexts/AuthContext"
import { Link } from "react-router-dom"

export default function ForgotPassword() {
    const emailRef = useRef()
    const { resetPassword } = useAuth()
    const [error, setError] = useState("")
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)

    async function handleSubmit(e) {
        e.preventDefault()

        try {
            setMessage("")
            setError("")
            setLoading(true)
            await resetPassword(emailRef.current.value)
            setMessage("Check your inbox for further instructions")
        } catch {
            setError("Failed to reset password")
        }

        setLoading(false)
    }

    return (
        <>
            <h2>Password Reset</h2>
            {error && <div>{error}</div>}
            {message && <div>{message}</div>}
            <form onSubmit={handleSubmit}>
                <label id="email">Email</label>
                <input type="email" ref={emailRef} required /><br/>
                <button disabled={loading} type="submit">Reset Password</button>
            </form><br/>
            <Link to="/login">Login</Link><br/>
            <Link to="/signup">Sign Up</Link>
        </>
    )
}
