import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router'

import { useAuth } from '../auth/contexts/AuthContext'
import { getChapter, setChapter, getCase, setCaseName } from '../reducers/caseSlice'
import  { getChapter as getFSChapter, getUserActiveCase, getUser } from '../../utils'
import { ProgressNoir } from '../library'

const Home = (props) => {
    const { userHasCase } = useAuth()
    const [ redirect, setRedirect ] = useState()
    const dispatch = useDispatch()
    const currentChapter = useSelector(getChapter)
    const activeCase = useSelector(getCase)

    useEffect(() => {
        determineRoute()
    }, [])

    useEffect(() => {
        if (!redirect) {
            return
        }
        const { history: { push } } = props
        setTimeout( () => push({
            pathname: redirect,
            hash: ``,
        }), 2000)
    }, [redirect])

    const determineRoute = async () => {
        const user = await getUser()
        const userCase = await userHasCase()
        if (user && userCase) {
            loadChapter()
            setRedirect(`/dashboard`)
        } else {
            setRedirect(`/welcome`)
        }
    }

    const loadChapter = async () => {
        if (currentChapter === 0) {
            // chapter must not be loaded
            const chapter = await getFSChapter()
            dispatch(setChapter(chapter))
        }
    }

    return (
        <div>
            <ProgressNoir />
        </div>
    )
}

export default Home