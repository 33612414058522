import { createSlice } from '@reduxjs/toolkit';
import settings from '../../settings.json';

interface debugType {
  active: any;
}

const initialState = {
  active: settings.debug || false,
};

export const debugSlice = createSlice({
  name: 'debug',
  initialState,
  reducers: {
    setDebugActive: (state, action) => {
      state.active = action.payload;
    },
  },
});

export const { setDebugActive } = debugSlice.actions;

export const getDebugActive = (state: { debug: { active: any } }) =>
  state.debug.active;

export default debugSlice.reducer;
