import { createSlice } from '@reduxjs/toolkit'

import { sceneType } from '../types/scene'

const initialState:sceneType | null = null

export const sceneSlice = createSlice({
    name: 'scene',
    initialState,
    reducers: {
        resetScene: () => {
            return initialState
        },
        setScene: (state, action) => {
            return action.payload
        }
    }
})

export const { resetScene, setScene } = sceneSlice.actions

export const getSceneID = (state: { activeScene: { id: any; }; }) => state.activeScene ? state.activeScene.id : null
export const getScene = (state: { activeScene: any; }) => state.activeScene


export default sceneSlice.reducer
