import { createSlice } from '@reduxjs/toolkit'
import settings from '../../settings.json'

interface caseType {
    chapter: number | null,
    name: string,
    complete: boolean,
}

const initialState: caseType = {
    chapter: null,
    name: settings.default.case,
    complete: false,
}

export const caseSlice = createSlice({
    name: 'case',
    initialState,
    reducers: {
        resetCase: () => {
            return initialState
        },
        setCase: (state, action) => {
            state.name = action.payload
            state.chapter = 0
        },
        setCaseName: (state, action) => {
            state.name = action.payload
        },
        setChapter: (state, action) => {
            if (action.payload >= 0) {
                state.chapter = action.payload
            }
        },
        setCaseComplete: (state, action) => {
            state.complete = action.payload
        },
    }
})

export const { resetCase, setCase, setCaseName, setChapter, setCaseComplete } = caseSlice.actions

export const getCase = (state: { case: {} }) => state.case
export const getCaseName = (state: { case: { name: string } }) => state.case.name
export const getChapter = (state: { case: { chapter: number } }) => state.case.chapter
export const getCaseComplete = (state: { case: { complete: boolean } }) => state.case.complete

export default caseSlice.reducer
