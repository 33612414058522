import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { getScene } from '../../reducers/sceneSlice'
import { giveUserItem, doesUserHaveItem } from '../../../utils'
import { MediaImage, MediaVideo } from '../../Media'

const CarKeys = () => {
    // const scene = useSelector(getScene)
    // const [ guess, setGuess ]  = useState<string | undefined>()
    // const [ msg, setMsg ] = useState<string | undefined>()
    // const [ userHasKeys, setUserHasKeys ] = useState(false)

    useEffect(() => {
        giveUserItem('car-key')
        // // check to see if user already has keys. if so, dont show guess input
        // if (doesUserHaveItem('car-key')) {
        //     setUserHasKeys(true)
        // }
    }, [])

    // const handleGuess = () => {
    //     if (guess?.toString() === '123') {
    //         giveUserItem('car-key')
    //         setUserHasKeys(true)
    //         setMsg(`Correct. You got the key.`)
    //     } else {
    //         setGuess('')
    //         setMsg(`'${guess}' is incorrect. Try again.`)
    //     }
    // }

    // if (userHasKeys) {
    //     return (<div className="component">You have the keys</div>)
    // }

    // return (
    //     <div className="component">
    //         <MediaVideo path={scene.media.path} file={scene.media.file}/>
    //         Custom Comp Car Keys (123)
    //         <div>{msg}</div>
    //         { !userHasKeys ? <input type="text" value={guess} onChange={(event) => setGuess(event.target.value)}></input> : null }
    //         <button onClick={handleGuess.bind(this)}>Guess</button>
    //     </div>
    // )

    return (
        <div className="component">
            You got the car key.
        </div>
    )
}

export default CarKeys