import React from 'react'
import { makeStyles } from 'tss-react/mui'
import clsx from 'clsx'

import { contentType, imageSeries } from '../types/content'
import { MediaImage, MediaVideo } from '../Media'
import { genHTMLContent, getImageSeriesImg } from '../../utils'
import Breadcrumbs from '../Breadcrumbs'

interface dataType {
  type: string
  value: contentType | any
  breadcrumbs?: boolean
}

interface contentComponentType {
  data: any
  altData?: any
  hideBreadcrumbs?: boolean
}

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      // image and breadcrumb container
      '& div:nth-of-type(2)': {
        // image
        paddingTop: 25,
      },
    },
  }
})

const Content = ({ data, altData, hideBreadcrumbs }: contentComponentType) => {
  const { classes } = useStyles()

  if (!data || data.length < 1) {
    return <></>
  }

  const content: JSX.Element[] = []

  const media = (item: any) => {
    if (item.type === 'image') {
      const { path, file, placeholder, name } = item
      return (
        <MediaImage
          path={path}
          file={file}
          placeholderFile={placeholder}
          name={name}
        />
      )
    } else if (item.type === 'video') {
      return <MediaVideo path={item.path} file={item.file} />
    } else {
      console.error('Missing media type:', item)
      return <div>Missing media type: {item.type}</div>
    }
  }

  const processLi = (item: any) => {
    return item.value.map((i: any, key: React.Key) =>
      genHTMLContent({ type: 'li', value: i }, key.toString())
    )
  }

  data.forEach(async (c: dataType, index: number) => {
    switch (c.type) {
      case 'imageSeries':
        if (
          !hideBreadcrumbs &&
          (((index === 1 || index === 0) && !c.breadcrumbs) || c.breadcrumbs)
        ) {
          // show breadcrumbs/back if this is the first <imageSeries>
          // unless breadcrumbs set to false
          // this might need to change if content becomes more complex
          content.push(<Breadcrumbs overlay={true} />)
        }
        const { path, images } = c.value
        for (var i = 0; i < images.length; i++) {
          // imageSeries may consist of an array of imgs, or an arrays of img objs
          const item = images[i]
          const itemImg = getImageSeriesImg({
            path,
            item,
          })
          const mediaItem = media(itemImg)
          if (mediaItem) {
            content.push(mediaItem)
          }
        }
        break
      case 'h1':
        if (((index === 1 || index === 0) && !c.breadcrumbs) || c.breadcrumbs) {
          // show breadcrumbs if this is the first <h1> or second <h1>
          // unless breadcrumbs set to false
          // this might need to change if content becomes more complex
          content.push(
            <h1>
              <Breadcrumbs /> {c.value}
            </h1>
          )
        } else {
          content.push(<h1>{c.value}</h1>)
        }
        break
      case 'h2':
        content.push(<h2>{c.value}</h2>)
        break
      case 'h3':
        content.push(<h3>{c.value}</h3>)
        break
      case 'h4':
        content.push(<h4>{c.value}</h4>)
        break
      case 'h5':
        content.push(<h5>{c.value}</h5>)
        break
      case 'h6':
        content.push(<h6>{c.value}</h6>)
        break
      case 'li':
        content.push(processLi(c))
        break
      case 'p':
        content.push(<p>{c.value}</p>)
        break
      case 'div':
        for (var i = 0; i < c.value.length; i++) {
          const mediaItem = media(c.value[i])
          content.push(mediaItem)
        }
        break
      default:
        if (!c?.value) {
          console.log('No value on:', c)
        }
        content.push(<div>{c?.value?.toString()}</div>)
    }
  })

  return (
    <div className={clsx('component', classes.root)}>
      {content.map((item, key) => (
        <div key={key}>{item}</div>
      ))}
    </div>
  )
}

export default Content
