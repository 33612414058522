// compile all globals
import directoryAF from './directory-a-f.json'
import directoryGO from './directory-g-o.json'
import directoryPZ from './directory-p-z.json'

export default {
    "id": "directory",
    "type": "geo",
    "locations": [
        {
            "lat": "000",
            "long": "000"
        }
    ],
    "media": {
        "type": "image/video",
        "path": "http/string/image/component/path"
    },
    "text": {
        "caption": "xxx",
        "heading": "The car exterior",
        "body": "A newish looking black Chevy Caprice, hard top, sat parked alongside the North end of Cal Anderson. The car looked like a somewhat sad, tired man in a cleaned used suit. A dim seattle sky pressing down on it. Shadowed, it was parked between street lights making sleuthing difficult in the poor lighting.  A small stack of soggy tickets clung to the windshield. The sour smell of something rotting coming from the trunk.  Spare keys where Clint said they’d be. "
    },
    "data": [
        ...directoryAF,
        ...directoryGO,
        ...directoryPZ
    ]
}