import React from 'react'

export default function About() {
    return (
        <div>
            <h2>About</h2>
            <p>v 0.0.15</p>
        </div>
    )
}
