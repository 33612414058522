import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { Slide, Fade } from 'react-slideshow-image' // https://react-slideshow.herokuapp.com/
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

import { MediaImage, MediaVideo } from '../index'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            position: 'relative',
            '& ul.indicators': {
                backgroundColor: 'transparent',
                '& li': {
                    height: '15px !important',
                    width: '15px !important',
                    padding: '10px !important',
                    '& .each-slideshow-indicator:before': {
                        background: '#fff',
                        height: '15px !important',
                        width: '15px !important'
                    }
                }
            }
        },
        container: {
            // position: 'relative',
            // zIndex: 100,
        }
    }
})

const MediaAutoShow = ({ content, onClick }) => {
    const { classes } = useStyles()
    const fadeRef = useRef()
    const [ mediaContent, setMediaContent ] = useState()

    useEffect(() => {
        setMediaContent(autplaySlideshow(content))
    }, [content])

    const handleClick = () => {
        onClick()
    }

    const autplaySlideshow = (content) => {
        const media = content.map((item, key) => {
            let slides
            if (item.type === 'image') {
                slides = <MediaImage path={item.path} file={item.file} placeholderFile={item.placeholder || null} />
            } else if (item.type === 'video') {
                slides = <MediaVideo path={item.path} file={item.file} />
            }
            return (
                <div key={key} className={clsx(classes.container, "each-slide")}>
                    {slides}
                </div>
            )
        })
        
        return (
            <div onClick={handleClick}>
                <Fade
                    autoplay={true}
                    ref={fadeRef}
                    duration={3000}
                    // transitionDuration: 1000,
                    // defaultIndex: 0,
                    arrows={false}
                    indicators={false}
                    easing="ease"
                    infinite={true}
                    pauseOnHover={false}
                    canSwipe={false}
                    scale={1}
                >
                    {media}
                </Fade>
            </div>
        )
    }

    // const next = () => {
    //  fadeRef.current.goNext()
    // }

    return (
        <>{mediaContent}</>
    )
}

export default MediaAutoShow