import React from 'react'
import { Button } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import clsx from 'clsx'

import { ProgressNoir } from '..'
import { custom } from '../../../themes/default'

interface buttonNoirType {
  name?: string
  variant?: 'contained' | 'contained' | 'outlined'
  loading?: boolean
  noirStyle?:
    | 'poiButtonStyles'
    | 'poiGlobalButtonStyles'
    | 'poiUnvisitedButtonStyles'
    | 'warning'
  // All other props
  [x: string]: any
}

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      borderRadius: 0,
      display: 'block',
      minHeight: 65,
      width: '80%',
      margin: '10px auto',
      fontSize: 16, //theme?.typography?.button?.fontSize || '1.3em',
      backgroundColor: theme?.palette?.primary?.[900],
      color: theme?.palette?.primary?.[100],
      fontFamily: theme?.typography?.button?.fontFamily,
      border: 'solid .5px rgba(255, 255, 255, .5)',
    },
    loadingContainer: {
      position: 'relative',
    },
    loadingOverlay: {
      position: 'absolute',
      left: '50%',
      top: '10%',
    },
    loadingBackground: {
      opacity: 0.2,
    },
  }
})

const noirStyles = {
  poiButtonStyles: {
    backgroundColor: '#16161d',
    border: 'solid .5px rgba(255, 255, 255, .5)',
  },
  poiGlobalButtonStyles: {
    backgroundColor: 'orange',
  },
  poiUnvisitedButtonStyles: {
    // backgroundColor: '#cbd5e1',
    // color: '#16161d',
    backgroundColor: custom.palette.buttons.unvisitedBGColor,
    color: custom.palette.buttons.unvisitedColor,
    border: 'solid #f8fafc 1px',
  },
  warning: {
    backgroundColor: 'darkred',
  },
}

const ButtonNoir = ({
  name = '',
  variant = 'contained',
  loading,
  noirStyle,
  ...props
}: buttonNoirType) => {
  const { classes } = useStyles()

  const loadingContent = () => {
    return (
      <div className={classes.loadingContainer}>
        <div className={classes.loadingOverlay}>
          <ProgressNoir size="20px" color="inherit" />
        </div>
        <div className={classes.loadingBackground}>{props.children}</div>
      </div>
    )
  }

  const combinedProps = {
    ...props,
    style: {
      ...props?.style,
      borderRadius: 0,
      ...noirStyles[noirStyle as keyof typeof noirStyles],
    },
  }

  return (
    <Button
      variant={variant}
      className={clsx(classes.root, props?.className)}
      disabled={loading}
      {...combinedProps}
    >
      {loading ? loadingContent() : props.children}
    </Button>
  )
}

export default ButtonNoir
