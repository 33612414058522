import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { getPOI } from '../../reducers/poiSlice'
import { customComponentType } from '../../types/customComponent'
import { pathContainsItem, setUserPOIStatus,addItemToPath } from '../../../utils'

const NorthernWayFollowUp = (props: customComponentType) => {
    const poi = useSelector(getPOI)

    useEffect(() => {
        buildResponse()
    }, [])

    const buildResponse = async () => {
        let altContent, altMedia
        // check if Ricks has been visited
        if (await pathContainsItem('ricks-nightclub')) {
            // if so, show 'ask about symptoms' copy
            altContent = poi.altContent.find((item: any) => item.id === 'subway-northern-way-ricks')
            altMedia = poi.altMedia.find((item: any) => item.id === 'subway-northern-way-ricks')
            await setUserPOIStatus({ id: 'northern-way-completed' })
            await addItemToPath('subway-northern-way-ricks')
        } else {
            // if not, show alt copy and user has to call into northern way later
            altContent = poi.altContent.find((item: any) => item.id === 'subway-northern-way-no-ricks')
            altMedia = poi.altMedia.find((item: any) => item.id === 'subway-northern-way-no-ricks')
            await setUserPOIStatus({ id: 'northern-way-callback-required' })
            await addItemToPath('subway-northern-way-no-ricks')
        }
        props.onPOIAltMedia(altMedia ? [altMedia] : null)
        props.onPOIAltContent(altContent ? [altContent] : null)
    }

    return (
        <></>
    )
}

export default NorthernWayFollowUp