import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getScene } from '../../reducers/sceneSlice';
import { setPuzzleStatus } from '../../../utils';
import { MediaImage, MediaVideo } from '../../Media';
import Content from '../../Content';

const reqId = 'the-goodbye-puzzle';

const TheGoodbye = () => {
  const scene = useSelector(getScene);
  const requirements = scene.requirements.find((req: any) => req.id === reqId);
  const [guess, setGuess] = useState<string>('');
  const [msg, setMsg] = useState<string | undefined>();
  const [userSovledPuzzle, setUserSolvedPuzzle] = useState(false);

  useEffect(() => {
    setPuzzleStatus({ id: reqId, status: 'visited' });
  }, []);

  const handleGuess = () => {
    if (guess?.toString() === '123') {
      setPuzzleStatus({ id: reqId });
      setMsg(`Correct. You solved the puzzle.`);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      setPuzzleStatus({ id: reqId, status: 'incorrect' });
      setGuess('');
      setMsg(`'${guess}' is incorrect. Try again.`);
    }
  };

  if (userSovledPuzzle) {
    return <div className="component">You've solved the puzzle</div>;
  }

  return (
    <div className="component">
      {/* <h2>{reqId} (123)</h2> */}
      <Content data={requirements.content} />
      <div>{msg}</div>
      {!userSovledPuzzle ? (
        <input
          type="text"
          value={guess}
          onChange={(event) => setGuess(event.target.value)}
        ></input>
      ) : null}
      <button onClick={handleGuess.bind(this)}>Guess</button>
    </div>
  );
};

export default TheGoodbye;
