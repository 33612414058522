import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { Profile, PrivateRoute, UpdateProfile } from './auth'
import POI from './POI'
import Scene from './Scene'
import Dashboard from './Dashboard'
import Items from './Items'
import Script from './Script'

export const PrivateRoutes = () => {
    return (
        <>
            <Switch>
                <PrivateRoute path="/profile" component={Profile} />
                <PrivateRoute path="/poi" component={POI} />
                <PrivateRoute path="/scene" component={Scene} />
                <PrivateRoute path="/items" component={Items} />
                <PrivateRoute path="/dashboard/:code?" component={Dashboard} /> 
                <PrivateRoute path="/script" component={Script} />
                {/* Alpha Hide: */}
                {/* Alpha Delete: */}
                {/* <PrivateRoute path="/update-profile" component={UpdateProfile} /> */}

                <Route exact path="/:id"
                    render={props => (
                        <Redirect to={`case/${props.match.params.id}`} />
                    )}
                />
            </Switch>
        </>
    )
}

export default PrivateRoutes