import imageSeriesImgType from '../components/types/imageSeries'

export const getImageSeriesImg = ({path, item}: {path: string, item: imageSeriesImgType | string}): imageSeriesImgType | null => {
    let image: imageSeriesImgType
    if (typeof item === 'string') {
        // array of imgs, keeping it simple
        image = {
            type: 'image',
            path,
            file: item
        }
    } else if (typeof item === 'object' && !Array.isArray(item) && item !== null && item.file) {
        // array of img objects
        image = {
            ...item,
            type: 'image',
            path,
        }
    } else {
        console.error('Malformed imageSeries:', item);
        return null
    }
    return image
}