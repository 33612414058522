import React from 'react'

import { SimpleGuess } from '../../puzzles'

const GimbsDeskDrawer1 = () => {
    
    return (
        <div className="component">
            <SimpleGuess 
                id={'gimbs-desk-drawer-1-lock'} 
                pathId={'inside-drawer-1'}
                solutions={['cwm']}
                successMsg="You've unlocked the drawer"
            />
        </div>
    )
}

export default GimbsDeskDrawer1