import { getUserActiveCase } from './user'
/*
 * Get settings
*/
export const getSettings = async () => {
    const settingsFile = await require(`../settings.json`)
    return settingsFile
}

export const getCasePathSettings = async (caseName: string, relativePath: string = '../../') => {
    return {
        caseFullRelativePath: `${relativePath}data/cases/${caseName}`,
        casePath: `data/cases/`,
        caseFolderName: caseName,
    }
}

export const getCaseData = async () => {
    // const settings = await getSettings()
    const activeCase = await getUserActiveCase()
    const caseDataFile = await require(`../data/cases/${activeCase.id}`)
    return caseDataFile.default
}