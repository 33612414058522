import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx'

import imagePlaceholder from './img-placeholder.jpg'
import imagePlaceholderSmall from './img-placeholder-small.jpg'

interface progressiveImgType {
    src: string, 
    placeholderSrc?: string | null, 
    defaultPlaceholderSmall?: boolean,
    defaultPlaceholderName?: boolean,
    defaultPlaceholderDisabled?: boolean,
    // All other props
    [x:string]: any,
}

const useStyles = makeStyles()((theme) => {
    return {
        loading: {
            filter: 'blur(2px)',
            clipPath: 'inset(0)'
        },
        loaded: {
            filter: 'blur(0px)',
            transition: 'filter 0.5s linear'
        }
    }
})

const ProgressiveImg = ({ src = '', placeholderSrc, defaultPlaceholderSmall, defaultPlaceholderName, defaultPlaceholderDisabled, ...props }: progressiveImgType) => {
    const { classes } = useStyles()
    const [imgSrc, setImgSrc] = useState(placeholderSrc || src)
    const [showLoading, setShowLoading] = useState(true)
    const defaultPlaceholderImg = defaultPlaceholderSmall ? imagePlaceholderSmall : imagePlaceholder
    const loadingClass = placeholderSrc && imgSrc === placeholderSrc ? classes.loading : classes.loaded
    
    useEffect(() => {
        setImgSrc(placeholderSrc && showLoading ? placeholderSrc : src)
    }, [placeholderSrc])

    useEffect(() => {
        let isMounted = true
        const loadImg = async () => {
            // This func is wrapped in useEffect with isMounted pattern to avoid react error:
            // "Can't perform a React state update on an unmounted component"
            const img: any = new Image()
            img.src = src
            img.onload = () => {
                if (isMounted) {
                    setImgSrc(src)
                    setShowLoading(false)
                }
            }
        }
        loadImg()
        return () => { isMounted = false }
    }, [src])

    const defaultLoading = () => {
        if (defaultPlaceholderDisabled) {
            return 
        } else {
            return showLoading && props?.alt && defaultPlaceholderName ? <h1>Loading {props.alt}....</h1> : <img src={defaultPlaceholderImg} />
        }
    }

    return (
        <>
            { imgSrc !== '' ? <img
                {...{ src: imgSrc, ...props }}
                alt={props?.alt ? props.alt : ''}
                className={clsx('image', loadingClass, props?.className)}
            /> : defaultLoading() }
        </>
    )
}

export default ProgressiveImg