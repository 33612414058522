function isGeolocationAvailable(): boolean {
  // Check if the browser supports geolocation
  return 'geolocation' in navigator
}

const isGeolocationEnabled = (): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    if (!isGeolocationAvailable()) {
      resolve(false)
      return
    }

    // Try to get the geolocation to check if the user has granted permission
    navigator.geolocation.getCurrentPosition(
      () => {
        resolve(true) // Permission granted, geolocation is enabled
      },
      (error) => {
        // If there is an error (like permission denied), reject
        if (error.code === error.PERMISSION_DENIED) {
          resolve(false) // Permission denied
        } else {
          resolve(false) // Other errors (e.g., timeout)
        }
      }
    )
  })
}

export { isGeolocationEnabled }
