// TODO: Replace the following with your app's Firebase project configuration
// For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
export const firebaseConfig = {
    apiKey: "AIzaSyCAlOcnZOLZKltVEL9DfL4zv8K8fO17F6M",
    authDomain: "capitol-noir.firebaseapp.com",
    databaseURL: "https://capitol-noir-default-rtdb.firebaseio.com",
    projectId: "capitol-noir",
    storageBucket: "capitol-noir.appspot.com",
    messagingSenderId: "889411399909",
    appId: "1:889411399909:web:da7f63f8b9a871d672240c",
    measurementId: "G-B2EDFR63LY"
}