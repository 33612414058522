import mainMap from './map-bw.jpg'
import mainMapPlaceholder from './map-bw-placeholder.jpg'
import calAndersonMap from './map-cal-anderson.jpg'

const maps = {
    main: {
        name: 'Capitol Hill',
        map: mainMap,
        placeholder: mainMapPlaceholder,
        width: 1060,
        height: 1130,
        buffer: 0.000500,
        haloPadding: 0.0005,
        haloStylePadding: 20,
        coords: {
            north: 47.622049,
            south: 47.614145,
            east: -122.316978,
            west: -122.327964
        }
    },
    details: [
        {
            name: 'Cal Anderson',
            displayName: true,
            map: calAndersonMap,
            width: 140,
            buffer: 0.000500,
            haloPadding: 0.0005,
            haloStylePadding: 100,
            coords: {
                north: 47.618779,
                south: 47.615302,
                east: -122.318173,
                west: -122.320060,
            }
        }
    ]
}

export default maps