import React, { useContext, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { signInAnonymously } from 'firebase/auth'

import firebase, { auth, db } from "../../../firebase"
import { getUser, setUser } from '../../reducers/userSlice'
import { getUser as getUserFirebase, updateUserActiveCase, getUserActiveCase, clearDashQueue } from '../../../utils'
import settings from '../../../settings.json'
import { setMap } from '../../reducers/myLocationSlice'
import { resetPOI } from '../../reducers/poiSlice'
import { resetCase, setCase, setChapter, getCase, setCaseName } from '../../reducers/caseSlice'
import { resetScene } from '../../reducers/sceneSlice'

import { getFirestore, collection, addDoc } from 'firebase/firestore';

const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {
    const dispatch = useDispatch()
    const reduxUser = useSelector(getUser)
    const activeCase = useSelector(getCase)
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)

    function signup(email, password, displayName) {
        // return auth.createUserWithEmailAndPassword(email, password)
        return firebase.auth().createUserWithEmailAndPassword(email, password)
            .then(function (data) {
                const { uid } = data.user
                console.log('uid', uid)
                return {
                    uid
                }
            })
            .catch(function (error) {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                if (errorCode == 'auth/weak-password') {
                    alert('The password is too weak.')
                } else {
                    alert(errorMessage);
                }
                console.log(error)
            })

        // TODO: I was trying to set the display name at the same time as creating a new acct. I dont think it's possible?
        // const promises = [
        //     auth.createUserWithEmailAndPassword(email, password),
        //     currentUser.updateProfile({
        //         displayName: displayName,
        //     })
        // ]
        // return Promise.all(promises)
        //     .then(() => {
        //         // nothing
        //     })
        //     .catch((error) => {
        //         console.log("Failed to create account", error);
        //     })
        //     .finally(() => {
        //         // nothing
        //     })
    }

    function login(email, password) {
        resetCache()
        return auth.signInWithEmailAndPassword(email, password)
    }

    function logout() {
        resetCache({uid: true})
        // NOTE: dev needs to manually set user reducer to null. No way to dispatch from this file
        return auth.signOut()
    }

    function resetCache({ uid, resetTheCase } = { uid: false, resetTheCase: true }) {
        if (settings.debug) {
            console.log('***** reset cache *****');
        }
        if (resetTheCase) {
            dispatch(resetCase())
        }
        sessionStorage.setItem('activeCase', '')
        if (uid) {
            sessionStorage.setItem('uid', '')
        }
        return
    }

    async function resetCaseSettings({ resetLogout, caseId } = { resetLogout: true, caseId: null }) {
        try {
            if (resetLogout) {
                await logout()
            }
            resetCache({uid: resetLogout, resetTheCase: true})
            // dispatch(resetUser())
            // reset store and localstorage
            dispatch(setMap(''))
            dispatch(setCase(caseId ? caseId : ''))
            dispatch(setChapter(caseId ? 1 : null))
            dispatch(resetPOI())
            dispatch(resetScene())
            clearDashQueue()
            // dispatch(resetUser()) // I think this is reseting the DB
            await updateUserActiveCase({
                chapter: caseId ? 1 : null,
                id: caseId || '',
                dirty: false,
                caseComplete: false,
                poi: [],
                items: [],
                puzzles: [],
                path: [],
                scenes: [],
                dashScenesQueue: []
            })
            return true
        } catch (e) {
            console.log('Failed to reset case and log out:', e);
            return false
        }
    }

    function resetPassword(email) {
        return auth.sendPasswordResetEmail(email)
    }

    function updateEmail(email) {
        return currentUser.updateEmail(email)
    }

    function updatePassword(password) {
        return currentUser.updatePassword(password)
    }

    function updateProfile(profile) {
        // return currentUser.updateProfile(profile)
        return currentUser.updateProfile(profile)
            .then(function () {
                console.log('User Profile Updated Successfully');
            }).catch(function (error) {
                console.log('updaet profile error', error);
            })
    }

    async function createAnonymousUser() {
      console.log('aatempt to sign in anonymousely...');
        // return await auth.signInAnonymously()

        // try {
        //   // Add a document to the 'testCollection' with a field called 'message'
        //   const docRef = await addDoc(collection(db, 'testCollection'), {
        //     message: 'Hello World'
        //   });
        //   console.log('Document written with ID: ', docRef.id);
        // } catch (error) {
        //   console.error('Error adding document: ', error);
        // }

        // Sign in anonymously
      return await signInAnonymously(auth)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log('User signed in anonymously:', user);
        return user
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error('Error signing in anonymously:', errorCode, errorMessage);
      });
    }

    async function userHasCase() {
        // TODO: possibly put this in a new non-auth context?
        if ((!activeCase.name || activeCase.name === '')) {
            // case may not be loaded, attempt to load from db
            const dbCase = await getUserActiveCase()
            if (dbCase?.id && dbCase.id !== '') {
                dispatch(setCaseName(dbCase.id))
                return true
            }
            return false
        }
        return true
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
          console.log('\n auth.unsubscribe()');
            if (!user) {
                setCurrentUser(user) // TODO: this may not be needed anymore. rely on redux state val instead?
                setLoading(false)
                return
            }

            const stateUser = {
                uid: user.uid,
                displayName: user.displayName,
                email: user.email
            }

            // get user from auth
            // check if user data is loaded into store
            // if not, then get it from firebase
            // load it into the store

            sessionStorage.setItem('uid', user.uid)

            // set user info & status (if its not set, or it does not match firebare auth)
            if (!reduxUser || reduxUser.uid !== user.uid) {
                async function fetchUserFromFirebase() {
                    // get user game status from firebase
                    const userFirebase = await getUserFirebase(user.uid)
                    if (!userFirebase) {
                        alert(`User ${user.uid} not loaded. Check internet connection. DB permissions.`);
                        alert('forcing logout.')
                        auth.signOut();
                        sessionStorage.removeItem('uid');
                    }
                    if (settings.debug) {
                        console.log('user from firebase', userFirebase);
                    }
                    // TODO: set user game status in redux store
                    // items, poi, etc...
                }
                // set active user in redux, based on auth obj
                dispatch(setUser(stateUser))
                fetchUserFromFirebase()
            }

            // set user
            setCurrentUser(user) // TODO: this may not be needed anymore. rely on redux state val instead?
            setLoading(false)
        })

        return unsubscribe
    }, [])

    const value = {
        currentUser,
        login,
        signup,
        logout,
        resetCache,
        resetPassword,
        resetCaseSettings,
        updateEmail,
        updatePassword,
        updateProfile,
        createAnonymousUser,
        userHasCase,
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}
