import userType from '../components/types/user'
import { caseDataType } from '../components/types/case'
import { db } from '../firebase'
import { getCaseData, getUserActiveCase, updateUserActiveCase } from '../utils'

/*
 * Middle Men
 * Data getters/setters
*/
export const doesUserHaveItem = async (id: string) => {
    const userItems = await getAllUserItems({})
    return userItems.includes(id)
}

export const giveUserItem = async (id: string) => {
    // add the item to the user's active case, if they dont have it already
    const activeCase = await getUserActiveCase()
    if (!activeCase) {
        return false
    }
    const items: string[] = activeCase.items ? activeCase.items : []
    const itemExists = items.find(item => item === id)
    if (!itemExists) {
        items.push(id)
        updateUserActiveCase({
            items
        })
    }
}

export const getAllUserItems = async ({ asObject = false }) => {
    // return either an array of items as simple strings, or objects
    const activeCase = await getUserActiveCase()
    if (!activeCase) {
        return false
    }
    let items = activeCase.items ? activeCase.items : []
    if (asObject) {
        const caseData: caseDataType | any = await getCaseData()
        items = items.map((item: string) => caseData.items.find((i: any) => i.id === item))
    }
    return items
}

/*
 * Case Items getters
*/

export const getAllCaseItems = async () => {
    const caseData: caseDataType | any = await getCaseData()
    return caseData.items
}

export const getItemById = async (id: string) => {
    const items = await getAllCaseItems()
    const item = items.find((item: { id: string }) => item.id === id)
    return item || null
}

export const getItemByIdWithCase = (id: string, caseData: caseDataType) => {
    const items = caseData.items
    const item = items.find((item: { id: string }) => item.id === id)
    return item || null
}