import React from 'react'

import { SimpleGuess } from '../../puzzles'

const GimbsDeskDrawer3 = () => {
    
    return (
        <div className="component">
            <SimpleGuess 
                id={'gimbs-desk-drawer-3-lock'} 
                pathId={'inside-drawer-3'}
                solutions={['auto']} 
                successMsg="You've unlocked the drawer"
            />
        </div>
    )
}

export default GimbsDeskDrawer3