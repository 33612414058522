import React, { useEffect, useState, lazy, Suspense } from 'react'
import { useParams } from 'react-router-dom'

import codes from './codes.json'
import { ProgressNoir } from '../library'

const Code = () => {
    const { code } = useParams<any>()
    const [ codeComponent, setCodeComponent ] = useState<JSX.Element>()
    
    useEffect(() => {
        if (code) {
            try {
                type codesKeyType = keyof typeof codes
                const componentName = codes[code as codesKeyType]
                if (componentName) {
                    const CodeComponent = lazy(() => import(`./codes/${componentName}`))
                    setCodeComponent(<CodeComponent />)
                } else {
                    console.log(`Code '${code}' not found.`);
                }
            } catch (e) {
                console.error('Error loading code component:', e);
            }
        }
    }, [])

    return (
        <>
            <Suspense fallback={<ProgressNoir color="secondary" />}>
            { codeComponent }
            </Suspense>
        </>
    )
}


export default Code