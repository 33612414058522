import { createTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

const custom = {}

const theme = createTheme({
  palette: {
    background: {
      default: '#000', //transparent',//'#16161d',
    },
    primary: {
      50: '#f8fafc',
      100: '#f1f5f9',
      200: '#e2e8f0',
      300: '#cbd5e1',
      400: '#94a3b8',
      500: '#64748b',
      600: '#475569',
      700: '#334155',
      800: '#1e293b',
      900: '#16161d', // eigengrau
    },
    neutral: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#e5e5e5',
      300: '#d4d4d4',
      400: '#a3a3a3',
      500: '#737373',
      600: '#525252',
      700: '#404040',
      800: '#262626',
      900: '#171717',
    },
    danger: {
      50: '#fef2f2',
      100: '#fee2e2',
      200: '#fecaca',
      300: '#fca5a5',
      400: '#f87171',
      500: '#ef4444',
      600: '#dc2626',
      700: '#b91c1c',
      800: '#991b1b',
      900: '#7f1d1d',
    },
    success: {
      50: '#f7fee7',
      100: '#ecfccb',
      200: '#d9f99d',
      300: '#bef264',
      400: '#a3e635',
      500: '#84cc16',
      600: '#65a30d',
      700: '#4d7c0f',
      800: '#3f6212',
      900: '#365314',
    },
    text: {
      primary: '#fff',
    },
    action: {
      disabledBackground: '#232733',
      disabled: 'white',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1000, // 1280 mui default
      xl: 1280, // 1920 mui default
    },
  },
  typography: {
    fontFamily: `'Helvetica', 'sans-serif'`,
    h1: {
      fontSize: '4rem',
    },
    h2: {
      fontSize: '2.75rem',
    },
    h3: {
      fontSize: '2rem',
    },
    h4: {
      fontSize: '1.125rem',
    },
    h5: {
      fontSize: '1rem',
    },
    h6: {
      fontSize: '0.5rem',
    },
    subtitle1: {
      fontFamily: `'Arial', 'Sans-serif'`,
    },
    subtitle2: {
      fontFamily: `'Arial', 'Sans-serif'`,
      fontSize: '0.8rem',
    },
    button: {
      fontFamily: 'serif',
      fontSize: '1.8em',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 0,
        // backgroundColor: 'red',
      },
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        // html: {
        //   fontSize: "62.5%" /* 62.5% of 16px = 10px */,
        //   fontFamily: "Poppins, sans-serif"
        // },
        // body: {
        //   margin: "0",
        //   color: "red",
        //   boxSizing: "border-box",
        //   fontFamily: "Poppins, sans-serif",
        //   backgroundColor: "#E3E3E3"
        // }
        button: {
          // backgroundColor: 'red !important',
        },
      },
    },
  },
  custom,
})

export default theme

export { custom }
