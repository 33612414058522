import { initializeApp, getApps, getApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

import { firebaseConfig } from './config';

// Check if any Firebase apps have been initialized
let app;
if (!getApps().length) {
  app = initializeApp(firebaseConfig); // Initialize with your Firebase configuration
  console.log('Firebase initialized');
} else {
  app = getApp();  // Get the existing app instance
  console.log('Firebase already initialized, using existing instance');
}

const db = getFirestore(app);

export const auth = getAuth(app);

export default app

export { db }

// Emulator
// https://firebase.google.com/docs/emulator-suite/connect_firestore

// export const auth = firebase.auth();
// auth.useEmulator("http://localhost:9099");

// import { getAuth, connectAuthEmulator } from "firebase/auth";

// export const auth = getAuth();
// connectAuthEmulator(auth, "http://localhost:9099");