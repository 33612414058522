import React from 'react'

import { MediaImage, MediaVideo } from '../index'

const MediaSingleShow = ({ content }: any) => {

    const singleSlide = () => {
        const item = content[0]
        let slide
        if (item.type === 'image') {
            slide = <MediaImage path={item.path} file={item.file} placeholderFile={item.placeholder || null} name={item.name || item.file}/>
        } else if (item.type === 'video') {
            slide = <MediaVideo path={item.path} file={item.file} />
        }
        return slide
    }

    return <>{singleSlide()}</>
}

export default MediaSingleShow