import { requirementType } from '../components/types/requirements'
import { getUserPOIWithStatus, getScenesWithStatus} from '../utils'

export const missingReqs = async (reqs: requirementType[]) => {
    // return only poi that have all requirments, && return only visited scenes
    const visitedPOI: any = await getUserPOIWithStatus()
    const visitedScenes: any = await getScenesWithStatus({status: 'visited'})
    const poiVisited = visitedPOI.map( (req: any) => req.id)
    const scenesVisited = visitedScenes.map( (req: any) => req.id)
    const reqsVisited = [...poiVisited, ...scenesVisited]

    for (let i = 0; i < reqs.length; i++) {
        console.log('check for res:', reqs[i]);
        if (reqs && reqs.length) {
            // check if missing reqs
            const missingReqPOI = reqs
                .map( (p: any) => p.id)
                .filter( (p: any) => !reqsVisited.includes(p))
            if (missingReqPOI?.length > 0) {
                return true
            }
        }
    }
    console.log('made it to the end of the line buddy');
    
    return false
}