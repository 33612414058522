import React, { useState, useEffect, useMemo } from 'react'

import { MediaImage, MediaVideo } from '../index'
import Inspector from '../../Inspector'

const MediaClickShow = ({ content }: any) => {
    const [ activeSlide, setActiveSlide ] = useState(0)
    const [ slides, setSlides ] = useState<any[]>([])
    const [ inspectorZoom, setInspectorZoom ] = useState<boolean>(false)

    useEffect(() => {
        setSlides(clickSlideshow(content))
    }, [content])

    const next = () => {
        /*
            img -> next
            img -> next
            insp -> zoom
            insp w zoom -> next
        */
        if (!slides[activeSlide]) { 
            return
        } else if ((slides[activeSlide].type !== 'inspector') || (slides[activeSlide].type === 'inspector' && inspectorZoom === true)) {
            const nextIndex = activeSlide < slides.length - 1 ? activeSlide + 1 : 0
            setActiveSlide(nextIndex)
        }
    }

    const clickSlideshow = (content: any) => {
        const contentSlides: any[] = []
        content.forEach((item: any, key: React.Key) => {
            let slide
            let type = item.type
            if (item.inspector) {
                type = 'inspector'
                slide = <Inspector 
                    autoZoom={false}
                    src={`${item.path}/${item.file}`}
                    onChange={(zoom: boolean): any => setInspectorZoom(zoom)}
                    hideHelper={true}
                    //placeholderFile={item.placeholder ? `${item.path}/${item.placeholder}` : null}
                />
            } else if (item.type === 'image') {
                slide = 
                <div onClick={() => next()}>
                    <MediaImage path={item.path} file={item.file} placeholderFile={item.placeholder || null} />
                </div>
            } else if (item.type === 'video') {
                slide = 
                <div onClick={() => next()}>
                    <MediaVideo path={item.path} file={item.file} />
                </div>
            }
            contentSlides.push(
                {
                    content: <div key={key}>{slide}</div>,
                    type
                }
            )
        })
        return contentSlides
    }

    return (
        <div onClick={() => next()}>
            {slides[activeSlide]?.content}
        </div>
    )
}

export default MediaClickShow