import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { useAuth } from '../auth/contexts/AuthContext'
import MyLocation from '../MyLocation'
import Map from '../Map'
import DashScene from '../DashScene'
import Code from '../Code'
import { DashboardBanner } from '.'
import { getCaseDataFile, getChapterStatus } from '../../utils'
import settings from '../../settings.json'
import { getChapter } from '../reducers/caseSlice'
import { caseDataType } from '../types/case'

const Dashboard = () => {
  const { userHasCase } = useAuth()
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [caseDataFile, setCaseDataFile] = useState<caseDataType>()
  const [chapterStatus, setChapterStatus] = useState<any>()

  const currentChapter = useSelector(getChapter)

  useEffect(() => {
    checkIfUserHasCase()
    getSettings()
    loadChapterStatus()
  }, [])

  const loadChapterStatus = async () => {
    const status = await getChapterStatus(currentChapter)
    setChapterStatus(status)
  }

  const checkIfUserHasCase = async () => {
    // if user doesnt have case, send them home
    const userCase = await userHasCase()
    if (!userCase) {
      history.push(`/welcome`)
    } else {
      setLoading(false)
    }
  }

  const getSettings = async () => {
    // get settings, case data & case maps
    const caseDataFile = await getCaseDataFile()
    setCaseDataFile(caseDataFile.default)
  }

  if (loading) {
    return <>Loading Dashboard....</>
  }

  // TODO: is there a way to pass caseData to Map to reduce calls?
  // as in: <Map caseDataFile={caseDataFile} />

  // hide the map if case complete and defined in case settings
  const hideMap =
    caseDataFile?.caseComplete?.hideMap && chapterStatus?.caseComplete
      ? true
      : false

  return (
    <>
      {!hideMap && <Map />}
      <Code />
      <DashScene caseDataFile={caseDataFile || {}} />
      {/* <MyLocation /> */}
    </>
  )
}

export default Dashboard
