import MediaShow from "./MediaShow";
import MediaAutoShow from "./MediaAutoShow";
import MediaClickShow from "./MediaClickShow";
import MediaSingleShow from "./MediaSingleShow";

export default MediaShow

export {
    MediaAutoShow,
    MediaClickShow,
    MediaSingleShow
}