import { getCaseData } from '../utils'

export const getAltContentById = async (id: string) => {
    const caseData = await getCaseData()
    const allPOI = caseData.chapters.flat()
        .map((chapter: { poi: any }) => chapter.poi)
    const flatPOI = allPOI.flat()
    const allAltContent = flatPOI
        .filter((poi: any) => poi.altContent)
        .map((item: any) => item.altContent)
        .flat()
    const foundItem = allAltContent.find((item: any) => item.id === id)
    return foundItem
}