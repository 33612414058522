import React from 'react'

import { SimpleGuess } from '../../puzzles'

const GimbsDeskDrawer4 = () => {
    
    return (
        <div className="component">
            <SimpleGuess 
                id={'gimbs-desk-drawer-4-lock'} 
                pathId={'inside-drawer-4'}
                solutions={['no solids']} 
                successMsg="You've unlocked the drawer"
            />
        </div>
    )
}

export default GimbsDeskDrawer4