import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from 'tss-react/mui';

import telephone from '../../../../data/cases/001-the-mollusck/global/telephone.json'
import prefixDirectory from './Telephone/prefixDirectory.json'
import phoneBoothImage from '../../../../data/cases/001-the-mollusck/media/phone-booth/phone-booth.jpeg'
import phoneBookWhiteAE from '../../../../data/cases/001-the-mollusck/media/phone-booth/phone-book-a-e.jpg'
import phoneBookWhiteGH from '../../../../data/cases/001-the-mollusck/media/phone-booth/phone-book-g-h.jpg'
import phoneBookWhiteIM from '../../../../data/cases/001-the-mollusck/media/phone-booth/phone-book-i-m.jpg'
import phoneBookWhiteOZ from '../../../../data/cases/001-the-mollusck/media/phone-booth/phone-book-o-z.jpg'
import phoneBookYellowAH from '../../../../data/cases/001-the-mollusck/media/phone-booth/phone-book-yellow-pages-a-h.jpg'
import phoneBookYellowIP from '../../../../data/cases/001-the-mollusck/media/phone-booth/phone-book-yellow-pages-i-p.jpg'
import phoneBookYellowRZ from '../../../../data/cases/001-the-mollusck/media/phone-booth/phone-book-yellow-pages-r-z.jpg'

import { MediaImage } from '../../../Media'
import Breadcrumbs from '../../../Breadcrumbs'
import cannedResponses from './Telephone/canned-responses.json'
import { getChapter, getCaseName } from '../../../reducers/caseSlice'
import { addDashScenesToQueue, genHTMLContent, setUserPOIStatus, addItemToPath, missingReqs, getCaseChapterDataFile, pathContainsItem } from '../../../../utils'
import { poiType, poiArrayType } from '../../../../components/types/poi'
import { ButtonNoir, InputNoir } from '../../../library'

interface prefixType {
    name: string,
    zone?: string,
    hidden?: boolean,    
}

const phoneBookIndex = {
    'White Pages: A - E': phoneBookWhiteAE,
    'White Pages: G - H': phoneBookWhiteGH,
    'White Pages: I - M': phoneBookWhiteIM,
    'White Pages: O - Z': phoneBookWhiteOZ,
    'Yellow Pages: A - H': phoneBookYellowAH,
    'Yellow Pages: I - P': phoneBookYellowIP,
    'Yellow Pages: R - Z': phoneBookYellowRZ,
}

const useStyles = makeStyles()((theme) => {
    return {
        select: {
            width: '90%',
            display: 'flex',
            justifyContent: 'center',
            fontSize: 20,
            padding: 10,
            marginBottom: 20,
        },
    }
})

const Telephone = () => {
    const { classes } = useStyles()
    const [ prefix, setPrefix ] = useState(prefixDirectory[0].name)
    const [ dialedNumber, setDialedNumber ] = useState<number | null>()
    const [ response, setRepsonse ] = useState<string | React.ElementType>('')
    const [ phoneBook, setPhoneBook ] = useState()

    type phoneBookKeyType = keyof typeof phoneBookIndex

    const prefixValues = prefixDirectory.map((value: prefixType, key: number) => {
        const { name, zone, hidden } = value
        return (
            <option key={key} value={name}>{name}</option> 
        )
    })

    const updateDialedNumber = (e: React.FormEvent<HTMLInputElement>) => {
        const value = parseInt(e.currentTarget.value)
        if (typeof value !== 'number' || e.currentTarget.value.length > 4) {
            return
        }
        setDialedNumber(value)
    }

    const dialNumber = () => {
        if (!dialedNumber || dialedNumber < 999) {
            setRepsonse(`This is not a valid number.`)
            return
        }
        const number: string = `${prefix}-${dialedNumber}`.toLowerCase()
        setRepsonse(`operator connecting you to ${number}...`)
        // delay a few seconds as operator connects you...
        setTimeout(async () => {
            let pois: any
            let nextPhonePOI = null
            // get all instances of telephone number (pois), if any exist in current chapter.json
            const chapter = await getCaseChapterDataFile()
            pois = chapter.poi.filter(
                (item: any) => item.type === 'phone' && number === `${item.number.prefix}-${item.number.number}`
            )
            const neverAnswerPhoneFlag = pois.length && pois[0].neverAnswerPhone ? true : false // if there is a number, and it should never be picked up, use this flag
            // filter: unvisited pois
            pois = await Promise.all(
                pois.map(async (poi: any) => {
                    if (!await pathContainsItem(poi.id)) {
                        return poi
                    }
                })
            )
            pois = pois.filter((item: any) => item !== undefined)
            // get the first instance of poi that has no missing reqs
            for (let i = 0; i < pois.length; i++) {
                const reqs = pois[i].requirements
                if (reqs && reqs.length) {
                    // poi has reqs, check if complete
                    if (!await missingReqs(reqs)){
                        //reqs complete
                        nextPhonePOI = pois[i]
                        break;
                    }
                } else {
                    // poi has no reqs, must be the one
                    nextPhonePOI = pois[i]
                    break;
                }
            }
            if (nextPhonePOI) {
                // if a connection was found, update the response
                const content = nextPhonePOI.content.map((content: any) => genHTMLContent(content))
                setRepsonse(content || 'Invalid response')
                // if response contains id, set poi
                await setUserPOIStatus({
                    id: nextPhonePOI.id, 
                    status: 'visited',
                    foreignId: nextPhonePOI?.foreignId || null, 
                    foreignStatus: nextPhonePOI?.foreignId ? 'visited' : null
                })
                await addItemToPath(nextPhonePOI.id)
                // if response contains dashScenes, add 'em
                if (nextPhonePOI?.dashScenes && nextPhonePOI?.dashScenes.length) {
                    addDashScenesToQueue(nextPhonePOI.dashScenes)
                }
            } else {
                // ----- CANNED RESPONSE
                const randResponse = getCannedResponse(neverAnswerPhoneFlag)
                setRepsonse(randResponse)
            }
            // TODO: add, ------ NOT A VALID NUMBER
        }, 500)
    }

    const getCannedResponse = (absent: boolean = false) => {
        // return a random canned response
        const prefixLib = cannedResponses?.prefix || ''
        const suffixLib = cannedResponses?.suffix || ''
        type prefixKeyType = keyof typeof prefixLib
        type suffixKeyType = keyof typeof suffixLib
        let demeanor

        // figure out demeanor
        // const rand = Math.floor(Math.random() * 4)
        const lastDigit = dialedNumber ? parseInt(dialedNumber.toString().substring(dialedNumber.toString().length-1)) : 0
        if (absent || lastDigit < 1) {
            demeanor = "absent"
        } else if (lastDigit > 6) {
            demeanor = "bad"
        } else if (lastDigit > 3) {
            demeanor = "good"
        } else {
            demeanor = "neutral"
        }

        // get prefix
        const prefixes = prefixLib[demeanor as prefixKeyType]
        const prefixKey = Math.floor(Math.random() * prefixes.length)
        const prefix = prefixes[prefixKey]

        // get suffix
        const suffixes = suffixLib[demeanor as suffixKeyType]
        const suffixKey = Math.floor(Math.random() * suffixes.length)
        const suffix = suffixes[suffixKey]

        return `${prefix} ${suffix}`
    }

    const handlePhoneBook = (page: any) => {
        if (page === 'select') {
            return
        }
        setPhoneBook(page)
    }

    const handleKeyPress = (event: any) => {
        if(event.key === 'Enter'){
            dialNumber()
        }
    }

    return (
        <> 
            <MediaImage src={phoneBook ? phoneBook : phoneBoothImage} />
            <h2><Breadcrumbs />{telephone?.name}</h2>
            
            <select className={classes.select} onChange={(e) => handlePhoneBook(e.target.value)}>
                <option key={0} value={'select'}>Turn to page:</option>
                {Object.keys(phoneBookIndex).map((value: any, key: any) => <option key={key} value={phoneBookIndex[value as phoneBookKeyType]}>{value}</option> )}
            </select>

            <div>
                <select className={classes.select} onChange={(e) => setPrefix(e.target.value)}>{prefixValues}</select>
                <InputNoir type="number" maxLength="4" max="9999" value={dialedNumber || ''} onChange={(e: any) => e.target.value.length <= 4 ? updateDialedNumber(e) : {} } onKeyPress={handleKeyPress} />
                <ButtonNoir onClick={dialNumber}>Dial</ButtonNoir>
            </div>

            {response}
        </>
    )
}

export default Telephone