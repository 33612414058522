import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';

import { isPOI, showPOI } from '../../utils';
import { poiType } from '../types/poi';
import { setPOI } from '../reducers/poiSlice';
import { getChapter } from '../reducers/caseSlice';
import settings from '../../settings.json';
import { ButtonNoir } from '../library';

interface props {
  poi: poiType;
  style?: any;
  action?: any;
  children?: any;
  // All other props
  [x: string]: any;
}

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      width: '90%',
      marginBottom: 5,
    },
  };
});

const POIButton = ({ poi, style, action, children, ...props }: props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const chapter = useSelector(getChapter);
  const { classes } = useStyles();
  const [loading, setLoading] = useState(false);

  // const getUserPOIWithStatus = () => {
  //     let poi:{ [key: string]: any } = {}
  //     Object.keys(sessionStorage).forEach( (key: string) => {
  //         poi[key] = sessionStorage.getItem(key);
  //     });
  //     console.log('storage',poi);
  // }

  useEffect(() => {
    // verify true poi on load
    if (!isPOI(poi)) {
      console.error('Invalid POI type: ', poi);
    }
  }, []);

  const handleAction = async () => {
    setLoading(true);
    dispatch(setPOI(poi));
    await showPOI(poi).then((data) => {
      history.push('/poi');
    });
  };

  return (
    <ButtonNoir
      onClick={handleAction}
      loading={loading}
      className={classes.root}
      style={style}
      noirStyle={props?.noirStyle}
    >
      {children}
    </ButtonNoir>
  );
};

export default POIButton;
