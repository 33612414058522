import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { Slide, Fade } from 'react-slideshow-image' // https://react-slideshow.herokuapp.com/
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

import HelperOverlay from '../../HelperOverlay';
import { MediaImage, MediaVideo } from '../index'
import { MediaAutoShow, MediaClickShow, MediaSingleShow } from './index'

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            position: 'relative',
            '& ul.indicators': {
                backgroundColor: 'transparent',
                '& li': {
                    height: '15px !important',
                    width: '15px !important',
                    padding: '10px !important',
                    '& .each-slideshow-indicator:before': {
                        background: '#fff',
                        height: '15px !important',
                        width: '15px !important'
                    }
                }
            }
        },
        container: {
            // position: 'relative',
            // zIndex: 100,
        }
    }
})

const MediaShow = ({ content }) => {
    const { classes } = useStyles()
    const [ mediaContent, setMediaContent ] = useState()
    const [ showAutoShow, setShowAutoShow ] = useState(true)

    useEffect(() => {
        let media = ''
        if (content.length > 1 && showAutoShow) {
            media = <MediaAutoShow content={content} onClick={ () => setShowAutoShow(false) } />
        } else if (content.length > 1 && !showAutoShow) {
            media = <MediaClickShow content={content} />
        } else if (content.length === 1) {
            media = <MediaSingleShow content={content} />
        }
        setMediaContent(media)
    }, [content, showAutoShow])

    return (
        <div className={classes.root}>
            { content.length > 1 && showAutoShow ? <HelperOverlay 
                text={`Tap to Stop`} 
                darkOverlay={content[0].darkOverlay ? true : false} 
                timeout={3000} 
                fontSize='800%'
                onClick={() => setShowAutoShow(false)}
            /> : null }
            {mediaContent}
        </div>
    )
}

export default MediaShow