import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx'
import theme, { noirTheme } from '../../theme';

type HelperOverlayType = {
    text: string,
    fontSize?: string,
    darkOverlay?: boolean,
    timeout: number,
    onClick?: () => void,
}

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            position: 'absolute',
            top: '0',
            // transform: 'translateX(0%)', 
            zIndex: 100,
            width: '100%',
            color: noirTheme.palette.alphaLight.color,
            overflowWrap: 'break-word',
            fontWeight: 900,
            lineHeight: '.9em',
            textTransform: 'uppercase',
            fontFamily: 'arial black',
            // textShadow: '0px 0px 2px rgb(0 0 0 / .5)',
            // '-webkit-text-stroke': '2px rgb(255 255 255 / .1)',
        },
        darkOverlay: {
            color: noirTheme.palette.alphaDark.color,
        }
    }
})

const HelperOverlay = ({ text, darkOverlay, fontSize, onClick, timeout = 5000 }: HelperOverlayType) => {
    const { classes } = useStyles()
    const [value, setValue] = useState<string | null>(text)

    useEffect(() => {
        setTimeout(() => {
            setValue(null)
        }, timeout)
    }, [])

    const handleClick = () => {
        setValue(null)
        if (onClick) {
            onClick()
        }
    }

    return value ? 
        <div 
            style={{ fontSize: fontSize ? fontSize : '500%' }}
            className={clsx(classes.root, darkOverlay ? classes.darkOverlay : null)}
            onClick={handleClick}
        >{value}</div>
        : null
}

export default HelperOverlay