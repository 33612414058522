import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

// TODO: place these in useEffect loader so it doesnt break app when files move or dont load
import missingPersons from '../../../../data/cases/001-the-mollusck/global/missing-persons.json'
import missingPersonImage from '../../../../data/cases/001-the-mollusck/media/missing-persons/missing-persons-storyboard.jpeg'
import missingPersonImagePlaceholder from '../../../../data/cases/001-the-mollusck/media/missing-persons/missing-persons-storyboardp.jpeg'
import mpScriptImageDay1 from '../../../../data/cases/001-the-mollusck/media/day-1/script/missing-persons-optimism.jpg'
import mpScriptImageDay1TrunkVisited from '../../../../data/cases/001-the-mollusck/media/day-1/script/missing-persons-chevy-optimism.jpg'
import mpScriptImageDay2 from '../../../../data/cases/001-the-mollusck/media/day-2/script/missing-persons-where-to-go-on.jpg'
import mpScriptImageDay3 from '../../../../data/cases/001-the-mollusck/media/missing-persons/missing-persons-nothing.jpg'
import mpScriptImageDay4 from '../../../../data/cases/001-the-mollusck/media/missing-persons/missing-persons-overload.jpg'
import mpMedia1 from '../../../../data/cases/001-the-mollusck/media/missing-persons/media-optimism.jpg'
import mpMedia2 from '../../../../data/cases/001-the-mollusck/media/missing-persons/media-where.jpg'
import mpMedia3 from '../../../../data/cases/001-the-mollusck/media/missing-persons/media-nothing.jpg'

import Breadcrumbs from '../../../Breadcrumbs'
import { MediaImage } from '../../../Media'
import { getChapter } from '../../../reducers/caseSlice'
import Inspector from '../../../Inspector'
import { isPOIVisited, isSceneVisited } from '../../../../utils'

const MissingPersons = () => {
    const [mpScriptImageDays, setMpScriptImageDays] = useState<string[]>([])
    const currentChapter = useSelector(getChapter || 1)

    const mpMedia = [
        mpMedia1,
        mpMedia2,
        mpMedia3,
        mpMedia3,
        mpMedia3,
        mpMedia3,
    ]

    useEffect(() => {
        checkConditionalScripts()
    }, [])

    const checkConditionalScripts = async () => {
        const trunkVisited = await isSceneVisited('trunk')
        const mpDay1 = trunkVisited?.status === 'visited' ? mpScriptImageDay1TrunkVisited : mpScriptImageDay1
        setMpScriptImageDays(
            [
                mpDay1,
                mpScriptImageDay2,
                mpScriptImageDay3,
                mpScriptImageDay4,
                mpScriptImageDay4,
                mpScriptImageDay4,
            ]
        )
    }

    if (!mpScriptImageDays[currentChapter - 1]) {
        return <>Fresh out of paper</>
    }
    return (
        mpScriptImageDays.length && <>
            <Inspector 
                src={mpMedia[currentChapter - 1]} 
                placeholderSrc={missingPersonImagePlaceholder} 
                name="Missing Persons"
                darkHelperOverlay={true} />
            <h2><Breadcrumbs />{missingPersons?.name}</h2>
            <MediaImage src={mpScriptImageDays[currentChapter - 1]} name="Missing Persons Script" />
            {/* {identities.map( (identity, key) => <div key={key}>{identity.name}</div>)} */}
        </>
    )
}

export default MissingPersons