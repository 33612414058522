import { createSlice } from '@reduxjs/toolkit'

interface userType {
    uid: string,
    displayName?: string,
    email: string
}

const initialState: userType | null = null

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        resetUser: () => {
            return initialState
        },
        setUser: (state, action) => {
            return action.payload
        }
    }
})

export const { resetUser, setUser } = userSlice.actions

export const getUserID = (state: { user: { uid: string; }; }) => state.user ? state.user.uid : null
export const getUser = (state: { user: any; }) => state.user


export default userSlice.reducer
