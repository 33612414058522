import React from 'react'

import { SimpleGuess } from '../../puzzles'

const GimbsDeskDrawer2 = () => {
    
    return (
        <div className="component">
            <SimpleGuess 
                id={'gimbs-desk-drawer-2-lock'} 
                pathId={'inside-drawer-2'}
                solutions={['gin', 'max gin','gin max']} 
                successMsg="You've unlocked the drawer"
            />
        </div>
    )
}

export default GimbsDeskDrawer2