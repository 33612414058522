import React from 'react';
import { Link } from 'react-router-dom';

import WelcomeImage from '../../data/cases/eq/media/welcome/welcome.jpg';

const Welcome = () => {
  return (
    <Link to="/case/eq">
      <img src={WelcomeImage} style={{ width: '95vw' }} />
    </Link>
  );
};

export default Welcome;
