import React, { useState, useEffect, Suspense } from 'react'
import { makeStyles } from 'tss-react/mui';

import ProgressiveImg from '../ProgressiveImg'

interface Props {
    path?: string,
    file?: string,
    placeholderFile?: string,
    src?: string,
    name?: string,
}

const useStyles = makeStyles()((theme) => {
    return {
        container: {
            width: '100%',
            maxWidth: '100%'
        }
    }
})

const MediaImage = ({ path, file, placeholderFile, src, name }: Props) => {
    const { classes } = useStyles()
    const [imagePath, setImagePath] = useState<any>()
    const [imagePlaceholderPath, setImagePlaceholderPath] = useState<any>()

    // used with image type
    const image = path && file ? `${path}/${file}` : undefined
    const imagePlaceholder = path && placeholderFile ? `${path}/${placeholderFile}` : undefined

    // TODO: mark the diffs between image and src

    useEffect(() => {
        let isMounted = true
        const loadImg = async () => {
            // This func is wrapped in useEffect with isMounted pattern to avoid react error:
            // "Can't perform a React state update on an unmounted component"
            if (image) {
                // IMAGE TYPE: typically used on data POI
                // example: data/cases/001-the-mollusck/media/mollusck 2.jpeg
                // load placeholder first if exists
                
                if (imagePlaceholder) {
                    import(`../../${imagePlaceholder}`).then(placeholderImage => {
                        if (isMounted) {
                            setImagePlaceholderPath(placeholderImage.default)
                        }
                    }).catch(error => {
                        console.warn(`Placeholder file not found: /${imagePlaceholder}`);
                    }).finally(() => {
                        // load main image
                        // note: this code is exactly the same as below
                        import(`../../${image}`).then(image => {
                            if (isMounted) {
                                setImagePath(image.default)
                            }
                        }).catch(error => {
                            console.log('error:',error);
                            console.error(`Image file not found: /${image}`);
                        })
                    })
                } else {
                    // otherwiseload just load main image
                    // note: this code is exactly the same as above
                    import(`../../${image}`).then(image => {
                        if (isMounted) {
                            setImagePath(image.default)
                        }
                    }).catch(error => {
                        console.log('error:',error);
                        console.error(`Image file not found: /${image}`);
                    })
                }
            } else if (src) {
                // SRC TYPE: typically used on custom POI components
                // example: /static/media/mollusck 11.b2a27440.jpeg
                if (isMounted) {
                    setImagePlaceholderPath(placeholderFile)
                    setImagePath(src)
                }
            }
        }
        loadImg()
        return () => { isMounted = false }
    }, [image, src])
    
    return (
        // <img className={classes.container} src={imagePath} />
        <ProgressiveImg 
            placeholderSrc={imagePlaceholderPath} 
            src={imagePath} 
            className={classes.container}
            alt={name || file?.toString()}
            defaultPlaceholderSmall={true}
            defaultPlaceholderName={false}
            defaultPlaceholderDisabled={false}
        />
    )
}

export default MediaImage