import React, { useState } from 'react'
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from 'tss-react/mui';

import { setSceneStatus, getSceneMissingItemReqs, addItemToPath, getSceneMissingPuzzleReqs } from '../../utils'
import { sceneType } from '../types/scene'
import { setScene } from '../reducers/sceneSlice'
import settings from '../../settings.json'
import { ButtonNoir } from '../library'

interface componentType {
    scene: sceneType,
    parentScene?: sceneType,
    style?: any,
}

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            marginRight: 5,
            marginBottom: 5,
        }
    }
})

const Scene = ({ scene, parentScene, style }: componentType) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { classes } = useStyles()
    const { name } = scene
    const [ loading, setLoading ] = useState(false)

    const handleAction = async () => {
        setLoading(true)
        dispatch(setScene(scene))
        
        const fetchRequirements = async () => {
            const missingItems = await getSceneMissingItemReqs(scene)
            const puzzleReqs = await getSceneMissingPuzzleReqs(scene)
            return Promise.all([missingItems, puzzleReqs])
        }
        
        const reqs = await fetchRequirements()

        if (settings.debug) {
            console.log('Update user Scenes');
        }

        if (reqs[0].length >= 1 || reqs[1].length >= 1) {
            await setSceneStatus(scene.id, 'locked')
        } else {
            await setSceneStatus(scene.id, 'visited')
        }
        if(!scene.hideFromPath) {
            await addItemToPath(scene.id)
        }
        history.push("/scene", { parentScene: parentScene })
    }
    
    return(
        <ButtonNoir 
            onClick={handleAction} 
            loading={loading}
            className={classes.root}
            style={style}
        >{name}</ButtonNoir>
    )
}

export default Scene