const custom = {
  palette: {
    black: '',
    dark: '#16161d',
    darkRed: 'rgb(113 4 4)',
    brightRed: 'rgba(255, 0, 0, .8',
    buttons: {
      unvisitedBGColor: 'rgb(113 4 4)', // '#cbd5e1',
      unvisitedColor: '#fff', //'#16161d',
    },
  },
  outerGlow: {
    // -webkit-filter: drop-shadow(12px 12px 7px rgba(0, 0, 0, 0.5));
    filter: 'drop-shadow(rgba(255,255,255, 1) 0px 0px 3px)',
  },
}

export { custom }
