import main from './main'

import global from './global'

// compile all chapters
import ch01 from './chapters/01'
import ch02 from './chapters/02'
import ch03 from './chapters/03'
import ch04 from './chapters/04'
import ch05 from './chapters/05'
import ch06 from './chapters/06'

export default {
    ...main,
    ...global,
    chapters: [
        ch01,
        ch02,
        ch03,
        ch04,
        ch05,
        ch06,
    ]
}

