import { puzzleType } from '../components/types/puzzle'
import { getUserActiveCase, updateUserActiveCase, getAllChapterScenes } from '../utils'

interface setPuzzleStatus {
    id: string,
    status?: string
}

/*
 * Middle Men
 * Data getters/setters
*/

export const setPuzzleStatus = async ({id, status = 'complete'}: setPuzzleStatus) => {
    // set the puzzle status, create it if it doesnt already exist
    let puzzles = await getAllUserPuzzles({})
    const puzzle: puzzleType | any = Object.values(puzzles).find( (item: any) => item.id === id)
    if (!puzzle) {
        const casePuzzle: any = await getPuzzleById(id)
        const newPuzzle = {
            id: casePuzzle.id,
            status: status
        }
        puzzles = [
            ...puzzles,
            newPuzzle
        ]
    } else if (puzzle && status === 'visited') {
        // do nothing
        return
    } else {
        puzzle.status = status
    }

    updateUserActiveCase({
        puzzles
    })
}

export const getUserPuzzle = (id: string) => {
    
}

export const getAllUserPuzzles = async ({}) => {
    // return either an array of puzzles as simple strings, or objects
    const activeCase = await getUserActiveCase()
    if (!activeCase) {
        return false
    }
    let puzzles = activeCase.puzzles ? activeCase.puzzles : []
    // TODO: possibly add ability to return object of puzzles, not just array
    // if (asObject) {
    //     puzzles = puzzles.map( (item: string) => caseData.puzzles.find((i: any) => i.id === item))
    // }
    return puzzles ? puzzles : []
}

/*
 * Case puzzles getters
*/

export const getAllCasePuzzles = async () => {
    console.log('TODO: add an item in the session to store active chapter, then pass it here');
    const scenes = await getAllChapterScenes({})
    const puzzles = scenes
        .filter( (scene: any) => scene.requirements)
        .map( (scene: any) => {
            return scene.requirements.map( (req: any) => req)
        })
        .flat()
        .filter( (req => req.type === 'puzzle'))
    return puzzles
}

export const getPuzzleById = async (id: string) => {
    const puzzles = await getAllCasePuzzles()
    const puzzle = puzzles.find( (puzzle: any) => puzzle.id === id)
    return puzzle || null
}