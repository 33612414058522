import React from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from 'tss-react/mui';

import User from '../User'
import Menu from '../Menu'
import { getDebugActive } from '../reducers/debugSlice'
import { withRouter } from 'react-router-dom';

type componentType = {
    children: any,
    [x:string]: any;
}

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            marginBottom: 100
        }
    }
})

const ContainerChild = ({ children, ...props }: componentType) => {
    const {  location } = props;
    const pathName = location?.pathname.substr(location.pathname.length - (location.pathname.length-1))
    
    const { classes } = useStyles()
    const debugActive = useSelector(getDebugActive)
    return (
        <div id={`container-${pathName}`} className={classes.root}>
            { debugActive ? <User /> : null }
            <Menu />
            {children}
        </div>
    )
}

const Container = withRouter(({history, ...props}) => <ContainerChild children={props.children} {...props} />)

export default Container