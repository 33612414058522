import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom"

import subway from '../../../../data/cases/001-the-mollusck/global/subway.json'
import subwayImage from '../../../../data/cases/001-the-mollusck/media/subway/subway.jpg'

import { MediaImage } from '../../../Media'
import Breadcrumbs from '../../../Breadcrumbs'
import { getChapter, getCaseName } from '../../../reducers/caseSlice'
import { isPOIVisited, isSceneVisited, getPOIByType, getPOIById, showPOI, getPath } from '../../../../utils'
import { setPOI } from '../../../reducers/poiSlice'

interface destinationType {
    id: string,
    name: string,
}

const Subway = () => {
    const dispatch = useDispatch()
    const currentChapter = useSelector(getChapter || 1)

    const [destinations, setDestinations] = useState<destinationType[]>([])
    const [destination, setDestination] = useState<string>()
    const history = useHistory()

    useEffect(() => {
        getDestinations()
    }, [])

    const getDestinations = async () => {
        // get all destinations for the chapter
        const subwayPOI = await getPOIByType('subway')
        const poi = subwayPOI.map((poi: any) => {
            const { id, name, location, requirements } = poi
            return {
                id,
                name,
                location,
                requirements
            }
        })
        // filter destinations that have: no reqs or all reqs met
        let validDestinations = poi.filter( (item: any) => !item.requirements)
        const pathsVisited = await getPath()
        await Promise.allSettled(
            poi.map(item => {
                if (item.requirements && item.requirements.length) {
                    item.requirements.forEach(async (itemReq: any) => {
                        const visited = pathsVisited.includes(item.id)
                        // run a check against each possible type (and poi is not already visited)
                        if (
                            ((itemReq.type === 'poi' && await isPOIVisited(itemReq.id)) ||
                            (itemReq.type === 'scene' && await isSceneVisited(itemReq.id)) ||
                            (itemReq.type === 'chapter' && currentChapter.toString() === itemReq.id)) &&
                            !visited
                        ) {
                            validDestinations.push(item)
                        }
                    });
                }
            })
        )
        setDestinations(validDestinations)
    }
    
    const rideTheRails = async () => {
        const targetDestination = destinations.find( item => item.id === destination)
        if (targetDestination) {
            const poi = await getPOIById(targetDestination.id)
            dispatch(setPOI(poi))
            await showPOI(poi).then(data => {
                history.push("/poi")
            })
        }
    }

    const content = () => {
        if (!destinations || !destinations.length) {
            return <>You dont belong anywhere, but the hill. For now.</>
        }

        return (
            <div>
                <select onChange={(e) => setDestination(e.target.value)}>
                    <option></option>
                    {destinations.map( (dest, key) => <option key={key} value={dest.id}>{dest.name}</option> )}
                </select>
                <button onClick={rideTheRails}>Ride the rails</button>
            </div>
        )
    }

    return (
        <> 
            <MediaImage src={subwayImage} />
            <h2><Breadcrumbs />{subway?.name}</h2>
            {content()}
        </>
    )
}

export default Subway