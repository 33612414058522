import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { getChapter } from '../reducers/caseSlice'
import { poiArrayType, poiType } from '../types/poi'
import { content } from '../types/content'
import { sceneArrayType, sceneType } from '../types/scene'
import { makeStyles } from 'tss-react/mui';
import { getPath, getPOIChapter, genHTMLContent, getCaseDataFile, getAltContentById } from '../../utils'
import { ProgressNoir } from '../library'

import { 
    getUserActiveCase,
    doesUserHaveItem,
    getUserPOIWithStatus, 
    getScenesWithStatus,
    getSceneMissingItemReqs,
    getSceneById,
    getUserScene,
    getChapterByPathId } from '../../utils'
import { getPOIById } from '../../utils/poi'

interface caseItemsType {
    path?: string[]
}

const useStyles = makeStyles()((theme) => {
    return {
        poi: {
            marginLeft: 5,
            marginRight: 5,
        },
        scene: {
            marginLeft: 10,
            marginRight: 10,
        }
    }
})

const Script = () => {
    const { classes } = useStyles()
    const activeChapter = useSelector(getChapter)
    const [caseData, setCaseData] = useState<any>({})
    const [ chaptersHTML, setChaptersHTML ] = useState<any>()
    const [ endHTML, setEndHTML ] = useState<any>()
    const [ previousChapte, setPreviousChapter ]  = useState()
    const [ caseComplete, setCaseComplete ] = useState(false)
    // const activeChapter = useSelector(getChapter)
    // const poiVisited = getUserPOIWithStatus('visited') || []
    // const scenesVisited = getScenesWithStatus({status: 'visited'}) || []
    // const [ caseItems, setCaseItems ] = useState<caseItemsType>({
    //     path: []
    // })

    useEffect( () => {
        loadCaseData()
    }, [])

    useEffect( () => {
        fetchCaseItems()
        if (caseData?.id) {
            checkEndHTML()
        }
    }, [caseData])

    const loadCaseData = async () => {
        // get settings & case data
        const caseDataFile = await getCaseDataFile()
        setCaseData(caseDataFile.default)
    }

    const checkEndHTML = async () => {
        // only create end HTML if case complete
        const userActiveCase = await getUserActiveCase()
        if (userActiveCase.caseComplete) {
            setCaseComplete(true)
            const lastChapter = caseData.chapters[caseData.chapters.length-1]
            let endHTML = <h1>ENDIT (No Ending)</h1>
            if (lastChapter?.end) {
                endHTML = lastChapter.end.content.map( (content: any) => genHTMLContent(content))
            }
            endHTML = <><hr/>{endHTML}</>
            setEndHTML(endHTML)
        }
    }

    const fetchCaseItems = async () => {
        if (caseData && caseData.name) {
            const path: string[] = await getPath()
            const html = fetchChaptersHTML(path)
            html.then(data => {
                setChaptersHTML(data)
            })
        }
    }

    // get Intro section
    if (!caseData.intro) {
        return (<ProgressNoir />)
    }
    
    const introHTML = caseData.intro.content.map( (content: any) => genHTMLContent(content))

    /*
     * [Chapters]
     *     |
     *     [POI]
     *         |
     *         [Scenes]
     */
    
    //var previousPOIId: string
    let chapterIndex = 0
    
    const fetchChaptersHTML = async (path: string[]) => {
        if (!path) {
            return(<div>No path found</div>)
        }

        const html = await Promise.all(path.map( async (id: any, key: React.Key) => {
            // determine item type (poi or scene)
            const divKey = key //item.id
            let item: any, itemPOI: any, itemScene:any, itemChapter: any, altContent: any, missingPersons: any

            await Promise.all([
                itemPOI = await getPOIById(id),
                itemScene = await getSceneById(id),
            ])
            // check if chapter intro
            if (!itemPOI && !itemScene) {
                itemChapter = await getChapterByPathId(id)
            }
            // check if altContent
            if (!itemPOI && !itemScene && !itemChapter) {
                altContent = await getAltContentById(id)
            }
            // check if missing persons
            if (!itemPOI && !itemScene && !itemChapter && !altContent) {
                missingPersons = <div style={{color: 'red', fontSize: 30}}>{id}</div>
            }

            // append poi info
            if (itemChapter) {
                // CHAPTER - item must be a chapter start
                const chapter = caseData.chapters[itemChapter - 1]
                const chapterHTML = 
                    <div key={divKey}>
                        <hr />
                        {chapter.intro.content.map( (content: any) => genHTMLContent(content))}
                    </div>
                if (chapterIndex < caseData.chapters.length - 1 ) {
                    // advance the chapter
                    chapterIndex++
                }
                return <div key={divKey}>{chapterHTML}</div>
            } if (itemPOI) {
                // POI - item must be POI
                // gen poi content
                const poiHTML = itemPOI.content ? itemPOI.content.map( (content: any) => genHTMLContent(content)) : null
                return (
                    <div key={divKey}>
                        {poiHTML}
                    </div>
                )

            } else if (itemScene) {
                // SCENE - item must be a scene
                // append scene info
                // prefix with poi
                // check status of scene
                const userScene = await getUserScene(itemScene.id)
                if (!userScene) {
                    return (
                        <div key={divKey}>Error: Scene missing id: {id}</div>
                    )
                }
                if (userScene.status === 'locked') {
                    return (
                        <div key={divKey}>
                            <div className={classes.scene}>
                                <h1>{itemScene.name}</h1>
                                <div>Scene locked: {itemScene.id}</div>
                            </div>
                        </div>
                    )
                }

                const sceneHTML = itemScene.content ? itemScene.content.map( (content: any) => genHTMLContent(content)) : null
                const missingItems = await getSceneMissingItemReqs(item)
                if (missingItems && missingItems.length >= 1) {
                    return (
                        <div key={divKey}>
                            <div className={classes.scene}>
                                <h1>{itemScene.name}</h1>
                                {missingItems.map( (item: any, key: React.Key) => <div key={key}>{itemScene.error}</div>)}
                            </div>
                        </div>
                    )
                } else if (itemScene.type === 'puzzle' && !doesUserHaveItem(itemScene.id)) {
                    // TODO: this else-if condition is hacked together. scene.type is not reliable and neither is scene.id.
                    // should prbably use itemScene.reward?
                    return (
                        <div key={divKey}>
                            <div className={classes.scene}>
                                <h1>{itemScene.name}</h1>
                                <div>Solve this puzzle for: {itemScene.id}</div>
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div key={divKey}>
                            <div className={classes.scene}>{sceneHTML}</div>
                        </div>
                    )
                }
            } else if (altContent) {
                // ALT CONTENT - item must be alt content
                const poiAltContent = genHTMLContent(altContent)
                return  <div key={divKey}>{poiAltContent}</div>
            } else if (missingPersons) {
                return  <div key={divKey}>{missingPersons}</div>
            } else {
                console.log('Item not displayed:', id);
            }

        }))

        return html
    }

    return (
        <div className="component">
            {caseComplete ? <h1>Case<br/>Complete</h1> : null}
            {introHTML}
            {chaptersHTML}
            {endHTML}
        </div>
    )
}

export default Script