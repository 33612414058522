import { dashSceneType } from '../components/types/dashScene'
import { getPath } from './path'
import { getUserActiveCase, updateUserActiveCase, getCaseDataFile } from '../utils'

export const addDashScenesToQueue = async (dashScenes: string[]) => {
    const path: string[] = await getPath()
    // get dash scenes not in path
    const dashScenesToAdd = dashScenes.filter( scene => !path.includes(scene))
    // get preexisting dash scenes queue
    const dashScenesQueue = await getDashScenesQueue(true)
    const dashScenesFiltered = [...new Set([...dashScenesQueue, ...dashScenesToAdd])]; // distinct filter dashScenes array
    if (dashScenesFiltered.length) {
        return await updateUserActiveCase({ dashScenesQueue: dashScenesFiltered})
    }
}

export const getDashScenesQueue = async (returnIdsOnly: boolean = false) => {
    // get chapter dashScenes
    const activeCase = await getUserActiveCase()
    const caseDataFile = await getCaseDataFile()
    const chapterDashScenes: dashSceneType[] = caseDataFile?.default?.chapters[activeCase.chapter - 1]?.dashScenes || []
    // get dashScenes in queue from chapter dashScenes
    const dashScenesQueueIds = activeCase.dashScenesQueue ? activeCase.dashScenesQueue : []
    const dashScenesQueue = chapterDashScenes.filter( (scene: any) => dashScenesQueueIds.includes(scene.id))
    return returnIdsOnly ? dashScenesQueueIds : dashScenesQueue
}

export const clearDashQueue = async () => {
    return await updateUserActiveCase({ dashScenesQueue: [] })
}