import {
  sceneType,
  sceneArrayType,
  sceneStatusType,
} from '../components/types/scene';
import { poiType, poiArrayType } from '../components/types/poi';
import { caseDataType } from '../components/types/case';
import { getAllUserItems } from './items';
import { getAllUserPuzzles } from './puzzles';
import {
  getCaseData,
  getUserActiveCase,
  updateUserActiveCase,
  getChapter,
} from '../utils';
import { fsScene } from '../components/types/user';
import settings from '../settings.json';

interface getScenesWithStatusType {
  status?: 'visited' | 'locked' | 'complete' | 'not-visited';
  returnArrayOfIds?: boolean;
}

/*
 * Type Guard
 */
export const isScene = (arg: any): arg is sceneType => {
  // if (!arg || !arg.id) { return }
  if (
    !arg.id ||
    typeof arg.id !== 'string' ||
    !arg.name ||
    typeof arg.name !== 'string'
  ) {
    // TODO: check for sub types: content, coords, scenes
    return false;
  }
  return true;
};

/*
 * Middle Men
 * Data getters/setters
 */
export const getScenesWithStatus = async ({
  status = 'visited',
  returnArrayOfIds = false,
}: getScenesWithStatusType) => {
  // NOTE: getUserPOIWithStatus and getScenesWithStatus used to be similar. now getScenesWithStatus has addl params. might need to upgrade getUserPOIWithStatus in future
  const activeCase = await getUserActiveCase();
  if (!activeCase) {
    return false;
  }
  const scenes: string[] = activeCase.scenes ? activeCase.scenes : [];
  const sceneWithStatus = scenes.filter(
    (scene: any) => scene.status === status
  );

  if (returnArrayOfIds) {
    return sceneWithStatus.map((scene: any) => scene.id);
  } else {
    return sceneWithStatus;
  }
};

export const getUserScene = async (id: string) => {
  const activeCase = await getUserActiveCase();
  if (!activeCase) {
    return false;
  }
  const scene = activeCase.scenes.find((scene: fsScene) => scene.id === id);
  return scene ? scene : null;
};

export const setSceneStatus = async (id: string, status: sceneStatusType) => {
  // Save the Scene as an object to storage
  const activeCase = await getUserActiveCase();
  if (!activeCase) {
    return false;
  }
  const scenes: fsScene[] = activeCase.scenes ? activeCase.scenes : [];
  const userScene = scenes.find((item) => item.id === id);
  if (!userScene) {
    // create new scene item
    scenes.push({
      id,
      status,
    });
    return await updateUserActiveCase({ dirty: true, scenes });
  } else if (userScene && userScene.status !== status) {
    if (settings.debug) {
      console.log('update existing scene with stauts:', status);
    }

    userScene.status = status;
    return await updateUserActiveCase({ dirty: true, scenes });
  } else {
    // TODO: update existing? maybe not, because its too draining on firebase
    return null;
  }
};

export const getScenesVisitedReqComplete = async (
  scenesUnfiltered: sceneArrayType
) => {
  // get all scenes with 'visited requirements' complete
  let scenes: sceneArrayType = [];
  await Promise.all(
    scenesUnfiltered.map(async (scene: any) => {
      if (scene.requirements) {
        // return only scenes that have all visited requirments complete, or no scene requirements at all
        // check if no reqs or no scene reqs
        if (
          !scene.requirements ||
          !scene.requirements.some((req: any) => req.type === 'scene')
        ) {
          scenes.push(scene);
          return;
        }
        // check for scenes visited:
        let visitedScenes: any = await getScenesWithStatus({
          status: 'visited',
          returnArrayOfIds: true,
        });
        visitedScenes = Array.isArray(visitedScenes) ? visitedScenes : [];
        const missingReqScenes = scene.requirements.filter(
          (s: any) => !visitedScenes || !visitedScenes.includes(s.id)
        );
        if (!missingReqScenes || missingReqScenes.length <= 0) {
          // scene visted reqs complete
          scenes.push(scene);
          return;
        } else {
          // scene has outstanding reqs
          return;
        }
      } else {
        // ..or have no requirements
        scenes.push(scene);
        return;
      }
    })
  );
  return scenes;
};

export const getSceneMissingItemReqs = async (scene: sceneType) => {
  // get all missing item requirements for a specific scene
  if (!scene || !scene.requirements) {
    return [];
  }
  const userItems = await getAllUserItems({});

  const missingItems = scene.requirements
    .filter((req) => req.type === 'item')
    .filter((item) => !userItems.includes(item.id));

  return missingItems;
};

export const getSceneMissingPuzzleReqs = async (scene: sceneType) => {
  // get all missing puzzle requirements for a specific scene
  if (!scene) {
    console.error('getSceneMissingPuzzleReqs() missing scene');
    return [];
  }
  if (!scene.requirements) {
    return [];
  }
  const userPuzzles = await getAllUserPuzzles({});
  const missingPuzzles = scene.requirements
    .filter((req) => req.type === 'puzzle')
    .filter((item) => {
      const userPuzzle = userPuzzles.find(
        (puzzle: any) => puzzle.id === item.id
      );
      return !userPuzzle || userPuzzle.status !== 'complete';
    });
  return missingPuzzles;
};

/*
 * Case Scene getters
 */

export const getPOIOrSceneById = async (id: string) => {
  // const allPOI = caseData.chapters.flat().map((chapter: { poi: any }) => chapter.poi)
  const allScenes = await getAllChapterScenes({});
  return allScenes.find((scene) => scene.id === id);
};

export const getSceneById = async (id: string) => {
  const allScenes = await getAllChapterScenes({ scenesOnly: true });
  return allScenes.find((scene) => scene.id === id);
};

export const getAllChapterScenes = async ({
  activeChapter,
  flattened = true,
  scenesOnly = false,
}: {
  activeChapter?: number;
  flattened?: boolean;
  scenesOnly?: boolean;
}) => {
  const allSceneScenes: any[] = [];

  const getAllSceneScenes = (object: any) => {
    // step thru and return all scenes in scene
    var level = 1;
    for (var key in object) {
      if (isNaN(+key) && key !== 'scenes') {
        continue;
      }
      if (typeof object[key] == 'object') {
        if (object[key].id) {
          var objectIsPOI = false;
          // if object contains a 'scenes' prop and 'location', it must be a POI
          if (object[key].scenes && object[key].location) {
            objectIsPOI = true;
          }
          if (!scenesOnly || (scenesOnly && !objectIsPOI)) {
            // append item if (scenesOnly set to false, or scenes only set to true and object is scene)
            allSceneScenes.push(object[key]);
          }
          // allSceneScenes.push({
          //     id: object[key].id ? object[key].id : 'no id',
          //     required: object[key].required ? object[key].required : true,
          //     chapter: object[key].chapter ? true : false // pass this to remove chapter node later in code
          // })
        }
        var POIScenes = level + 1;
        level = Math.max(POIScenes, level);
        getAllSceneScenes(object[key]);
      }
    }
    return allSceneScenes;
  };

  // get all scenes up to current chapter
  const caseData: caseDataType | any = await getCaseData();
  let scenes: sceneArrayType;
  scenes = caseData.chapters
    .filter((ch: { chapter: number }) => {
      return activeChapter ? ch.chapter <= activeChapter : ch.chapter;
    })
    .map((chapter: { poi: poiArrayType }) => {
      allSceneScenes.length = 0;
      const allScenes = getAllSceneScenes(chapter.poi).filter(
        (item: any) => !['hidden', 'phone', 'geo', 'subway'].includes(item.type)
      );
      const poiScenes = allScenes.map((scene) => scene);
      if (flattened) {
        return poiScenes.flat();
      } else {
        return poiScenes;
      }
    });

  if (flattened) {
    scenes = scenes.flat();
  }

  return scenes;
};

export const isSceneVisited = async (id: string) => {
  const activeCase = await getUserActiveCase();
  if (!activeCase) {
    return null;
  }
  const scene = activeCase.scenes.find((scene: sceneType) => scene.id === id);
  return scene || null;
};

export const getRemainingReqScenes = async (
  includeVisited?: boolean
): Promise<sceneArrayType> => {
  let remainingScenes: sceneArrayType = [];
  const vistedScenes: sceneArrayType = [];
  const chapterNumber = await getChapter();
  const allScenes = await getAllChapterScenes({
    activeChapter: chapterNumber,
    scenesOnly: true,
  });
  const visitedScenes: any = await getScenesWithStatus({ status: 'visited' });
  const reqScenes = allScenes.filter((scene: any) => scene.required);
  remainingScenes = reqScenes.filter((scene: any) => {
    const scenesVisited = visitedScenes.map((req: any) => req.id);
    const visited = !scenesVisited.includes(scene.id);
    if (!visited) {
      // keep track of unvisited if param is true
      vistedScenes.push({
        ...scene,
        visited: true,
      });
    }
    return visited;
  });
  return includeVisited
    ? [...remainingScenes, ...vistedScenes]
    : remainingScenes;
};
