import { getCasePathSettings } from './settings'
import { getUserActiveCase, getChapter, getChapterString } from '../utils'

export const getSettingsFile = async () => {
    return await require(`../settings.json`)
}

export const getCaseDataFile = async () => {
    const activeCase = await getUserActiveCase()
    const { casePath, caseFolderName } = await getCasePathSettings(activeCase.id)
    return await require(`../${casePath}${caseFolderName}`)
}

export const getCaseChapterDataFile = async (reqChapter?: string) => {
    const chapter = getChapterString(reqChapter || await getChapter())
    const activeCase = await getUserActiveCase()
    const { casePath, caseFolderName } = await getCasePathSettings(activeCase.id)
    return await require(`../${casePath}${caseFolderName}/chapters/${chapter}.json`)
}

export const getCaseSubFolderFile = async (name: string) => {
    const activeCase = await getUserActiveCase()
    const { casePath, caseFolderName } = await getCasePathSettings(activeCase.id)
    return await require(`../${casePath}${caseFolderName}/${name}`)
}