import React, { useRef, useState } from "react"
import { useAuth } from "./contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import queryString from 'query-string'

import { createUser } from '../../utils'

export default (props) => {
    const emailRef = useRef()
    const passwordRef = useRef()
    const passwordConfirmRef = useRef()
    const { signup } = useAuth()
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    // get case from query params
    const params = queryString.parse(props.location.search)
    const paramCaseId = params.case || undefined

    async function handleSubmit(e) {
        e.preventDefault()

        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError("Passwords do not match")
        }

        try {
            setError('')
            setLoading(true)
            const newUser = await signup(emailRef.current.value, passwordRef.current.value, 'HydrogenX')
            setLoading(false)
            if (newUser) {
                // add user to firestore and redirect to home
                if(!createUser(newUser.uid, paramCaseId)) {
                    throw 'User not created in firestore'
                }
                history.push("/")
            }
        } catch (error) {
            setLoading(false)
            setError("Failed to create an account", error)
        }
    }

    return (
        <>
            <div>
                <h2>Sign Up</h2>
                {error && <div>{error}</div>}
                <form onSubmit={handleSubmit}>
                    <label id="email">Email</label>
                    <input type="email" ref={emailRef} required /><br/>
                    <label id="password">Password</label>
                    <input type="password" ref={passwordRef} required /><br/>
                    <label id="password-confirm">Password Confirmation</label>
                    <input type="password" ref={passwordConfirmRef} required /><br/>
                    <button disabled={loading} type="submit">Sign Up</button>
                </form>
            </div>
            <div>
                Already have an account? <Link to="/login">Log In</Link>
            </div>
        </>
    )
}
