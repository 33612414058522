import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import {
  getCaseSubFolderFile,
  getDashScenesQueue,
  addItemToPath,
  clearDashQueue,
} from '../../utils'

/*
 * Currently this only displays Chapter Start/End Script copy. DashScene is misleading. Might need to change/upgrade in future...
 *
 */

import { getChapter } from '../reducers/caseSlice' // Is it better to use redux or utils?
import {
  getPath,
  genHTMLContent,
  getChapterString,
  getChapterStatus,
} from '../../utils'

const DashScene = ({ caseDataFile }: { caseDataFile: any }) => {
  const currentChapter = useSelector(getChapter)
  const [caseData, setCaseData] = useState<any>({})
  const [content, setContent] = useState<any>()

  useEffect(() => {
    checkIfDashScenes()
  }, [])

  useEffect(() => {
    init()
  }, [currentChapter, caseDataFile])

  const init = async () => {
    // get settings & case data
    try {
      setCaseData(caseDataFile)

      // fetchCaseItems
      const chapterString = getChapterString(currentChapter)
      const caseActiveChapterFile = await getCaseSubFolderFile(
        `chapters/${chapterString}`
      )

      // run checks
      checkIfChapterIntro(caseActiveChapterFile)
    } catch (e) {
      console.error('Error fetching case items:', e)
    }
  }

  const checkIfDashScenes = async () => {
    // chec for dashScene
    const dashScenes = await getDashScenesQueue()
    if (dashScenes.length) {
      // display dashScene
      const dashScenesContent = dashScenes
        .map((scene: any) => scene.content)
        .flat()
      const dashScenesHTML = dashScenesContent.map((content: any) =>
        genHTMLContent(content)
      )
      setContent(dashScenesHTML)
      // add dashScenes to path and remove from queue
      const dashSceneIds = dashScenes.map((item: any) => item.id)
      await addItemToPath(dashSceneIds)
      await clearDashQueue()
    }
  }

  const checkIfChapterIntro = async (chapterDataFile: any) => {
    // TODO: [search for: checkIfChapterIntro] combine with similar code and move to case context
    const pathResponse: string[] = await getPath()
    const status = await getChapterStatus(currentChapter)

    // check if start of case: day 1, or if active chapter is not dirty
    if (status.chapterComplete && chapterDataFile.end) {
      // chapter end
      const chapterCompleteHTML = chapterDataFile.end.content.map(
        (content: any) => genHTMLContent(content)
      )
      setContent(chapterCompleteHTML)
    } else if (
      (pathResponse &&
        !pathResponse.length &&
        chapterDataFile?.intro?.content) ||
      !status.dirty
    ) {
      // chapter intro
      console.log('yyy chapterDataFile', chapterDataFile)

      const introHTML = chapterDataFile.intro.content.map((content: any) =>
        genHTMLContent(content)
      )
      console.log('yyy', introHTML)

      setContent(introHTML)
    }
  }

  return <div>{content}</div>
}

export default DashScene
